import { permissionRole } from "constants/models";

export interface WshingwellUserPaymentDetails {
  paymentAccountId: string;
  canAcceptPayments: boolean;
  canAcceptPayouts: boolean;
  currentlyDue: string[];
}

export type WshingwellUserPermissionRole = "INTERESTED" | "MEMBER" | "ADMIN";

export interface WshingwellUserPermissions {
  [key: string]: WshingwellUserPermissionRole[];
}

export type WshingwellUserRole =
  | "STUDENT"
  | "PARENT"
  | "ADMINISTRATOR"
  | undefined;

export interface WshingwellUserSettings {
  acceptedTerms: boolean;
  commOptIn: boolean;
  showOnboarding: boolean;
  firstExperience: boolean;
  emailNewsOptin: boolean;
  emailHostingOptin: boolean;
  emailAttendingOptin: boolean;
  role: WshingwellUserRole;
}

const DEFAULT_SETTINGS: WshingwellUserSettings = {
  acceptedTerms: false,
  commOptIn: false,
  showOnboarding: false,
  firstExperience: false,
  emailNewsOptin: false,
  emailHostingOptin: false,
  emailAttendingOptin: false,
  role: undefined
};

const DEFAULT_PAYMENT_SETTINGS: WshingwellUserPaymentDetails = {
  canAcceptPayments: false,
  paymentAccountId: "",
  canAcceptPayouts: false,
  currentlyDue: []
};

export class WshingwellUser {
  public id: string;
  public displayName: string;
  public email: string;
  public postalCode: string;
  public permissions: WshingwellUserPermissions;
  public photoURL: string;
  public settings: WshingwellUserSettings;
  public paymentDetails: WshingwellUserPaymentDetails;
  public createdAt: number;

  // eslint-disable-next-line
  public constructor(args: any) {
    this.id = args.id;
    this.displayName = args.displayName || "";
    this.email = args.email || "";
    this.postalCode = args.postalCode || "";
    this.permissions = args.permissions || {};
    this.photoURL = args.photoURL || "";
    this.settings = args.settings || DEFAULT_SETTINGS;
    this.paymentDetails = args.paymentDetails || DEFAULT_PAYMENT_SETTINGS;
    this.createdAt = args.createdAt?.seconds || 0;
  }

  public getOrganizations() {
    const orgs = Object.keys(this.permissions);

    return orgs.sort((a, b) => a.localeCompare(b));
  }

  public getName() {
    return this.displayName || this.email || "";
  }

  public isAdmin(orgId: string) {
    return this.permissions[orgId]?.includes(permissionRole.ADMIN);
  }
  public isAnyAdmin() {
    return Object.values(
      this.permissions
    ).some((orgPerms: WshingwellUserPermissionRole[]) =>
      orgPerms.includes(permissionRole.ADMIN)
    );
  }

  public isMember(orgId: string) {
    return this.permissions[orgId]?.includes(permissionRole.MEMBER);
  }

  public isInterested(orgId: string) {
    return this.permissions[orgId]?.includes(permissionRole.INTERESTED);
  }
}
