export const LANDING = "/";
export const SIGN_UP_NOT_FOUND = "/signup/not-found";
export const SIGN_IN = "/signin";
export const EMAIL_UNVERIFIED = "/verify";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const ACCOUNT_PASSWORD_RESET_CONFIRMATION =
  "/account-reset-password-confirmation";
export const ADMIN = "/admin";
export const ADMIN_USERS = "/admin/users";
export const ADMIN_USERS_CREATE = "/admin/users/create";
export const ADMIN_EXPERIENCES = "/admin/experiences";

export const ADMIN_ORG = "/admin/:organizationId";
export const ADMIN_EXPERIENCE = "/admin/:organizationId/:experienceId";
export const ADMIN_EXPERIENCE_REPORT =
  "/admin/:organizationId/:experienceId/report";

export const PASSWORD_FORGET = "/forgot-password";
export const PASSWORD_FORGET_CONFIRMATION = "/forgot-password-confirmation";
export const PASSWORD_RESET_CONFIRMATION = "/reset-password-confirmation";
export const PASSWORD_RESET = "/reset-password";
export const EXPIRED_EMAIL_RESET = "/expired-email-reset";
export const INVITATION_ACCEPT = "/invitations/:token";
export const PEOPLE = "/people";
export const PAYMENTS = "/payments";
export const GIVE = "/give";
export const RECEIVE = "/receive";
export const CAUSES = "/causes";
export const CAUSES_NEW = "/causes/new";
export const EXPERIENCES = "/experiences";
export const EXPERIENCES_NEW = "/experiences/new/:organizationId?";
export const EXPERIENCES_VIEW = "/experiences/:eventId";
export const EXPERIENCES_EDIT = "/experiences/:eventId/edit";
export const EXPERIENCES_RSVP = "/experiences/:eventId/rsvp";
// export const EXPERIENCES_RSVP_NOT_ATTENDING =
//   "/experiences/:eventId/not-attending";
export const EXPERIENCES_PUBLIC_VIEW = "/experiences/:eventId/public";
export const EXPERIENCES_INVITE = "/experiences/:eventId/invite";
export const EXPERIENCES_SUMMARY = "/experiences/:eventId/summary";
export const EXPERIENCES_ATTENDEES = "/experiences/:eventId/attendees";
export const EXPERIENCES_EMAIL_EDIT =
  "/experiences/:eventId/email/edit/:template";
export const EXPERIENCES_EMAIL_SEND =
  "/experiences/:eventId/email/send/:recipient";
export const ORGANIZATION = "/organization/:organizationId";
export const OAUTH = "/oauth";
export const ONBOARDING = "/onboarding/:redirectToEvent?/:eventId?";
export const ACTION = "/action";
export const VERIFY_EMAIL = "/verify-email";
export const RECOVER_EMAIL = "/recover-email";
export const CONTACTS_NEW = "/contacts/new";
export const CONTACT_INVITATION_SENT = "/contacts/invitation-sent";
export const PEOPLE_VIEW = "/profile/:userId";
export const ROLE_SELECT = "/select-role";
export const GROUP_SELECT = "/select-groups";
export const TERMS_CONDITIONS = "/terms";
export const VERIFIED_EMAIL_LANDING = "/verified-email";
