export class Organization {
  public id: string;
  public displayName: string;
  public description: string;
  public website: string;
  public photoURL: string;
  public taxExempt: boolean;
  public location: { address: string; lat: number; lng: number } | null;
  public servicePercent: number | null;
  public attendeeCut: number | null;
  public transactionCut: number | null;
  public waiveOnAccountFees: boolean | null;
  public stripeAccountId: string | null;

  // eslint-disable-next-line
  public constructor(params: any) {
    this.id = params.id;
    this.displayName = params.displayName ?? "";
    this.description = params.description ?? "";
    this.website = params.website ?? "";
    this.photoURL = params.photoURL ?? "";
    this.location = params.location ?? null;
    this.taxExempt = params.taxExempt ?? false;
    this.servicePercent = params.servicePercent ?? null;
    this.attendeeCut = params.attendeeCut ?? null;
    this.transactionCut = params.transactionCut ?? null;
    this.waiveOnAccountFees = params.waiveOnAccountFees ?? null;
    this.stripeAccountId = params.stripeAccountId ?? null;
  }
}

export default Organization;
