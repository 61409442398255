import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
  Hidden,
  Theme
} from "@material-ui/core";

import * as ROUTES from "constants/routes";
import { AuthContext } from "../Session";
import { links, HideTags } from "./links";
import themeColours from "theme/themeColours";

const useStyles = makeStyles((theme: Theme) => ({
  nav: {
    bottom: 0,
    boxShadow: theme.shadows[1],
    flex: 1,
    position: "fixed",
    width: "100%",
    background: "#FFFFFF",
    zIndex: 10
  },
  navButton: {
    minWidth: 70,
    color: "#C4C4C4",
    padding: "6px 10px 2px",
    "& .MuiBottomNavigationAction-label": {
      fontWeight: 600,
      fontSize: "10px",
      lineHeight: "18px",
      paddingTop: "4px"
    },
    "&.Mui-selected .nav-icon": {
      filter: themeColours().iconFilterCss
    }
  },
  selected: {
    color: theme.palette.tertiary.main
  },
  wrapper: {
    color: "inherit"
  },
  icon: {
    width: "20px",
    height: "18px",
    color: "currentColor"
  }
}));

const BottomNav = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const auth = useContext(AuthContext);
  const [value, setValue] = useState<string | null>(null);

  if (
    (location.pathname !== ROUTES.EXPERIENCES &&
      location.pathname !== ROUTES.HOME &&
      location.pathname !== ROUTES.ACCOUNT &&
      location.pathname !== ROUTES.PEOPLE &&
      location.pathname !== ROUTES.PAYMENTS) ||
    !auth.authUser
  ) {
    return null;
  }

  return (
    <Hidden mdUp implementation="js">
      <BottomNavigation
        showLabels
        className={classes.nav}
        value={location.pathname}
        onChange={(event, v) => {
          if (location && location.pathname !== v) {
            setValue(location.pathname);
            history.push(v);
          }
        }}
        id="bottom-menu"
      >
        {links.map((link) =>
          // eslint-disable-next-line no-undef
          link.hide === HideTags.HIDE ||
          (link.hide === HideTags.ADMIN_ONLY &&
            !auth?.user?.isAnyAdmin()) ? null : (
            <BottomNavigationAction
              key={link.title}
              className={classes.navButton}
              value={link.path}
              label={link.title}
              icon={
                <img
                  src={link.icon}
                  className={classNames(classes.icon, "nav-icon", {
                    active: value === ROUTES.HOME
                  })}
                  alt={link.title}
                />
              }
            />
          )
        )}
      </BottomNavigation>
    </Hidden>
  );
};

export default BottomNav;
