export type ThemeOption = "default" | "torstar" | "branksome";

export const theme = (): ThemeOption => {
  if (process.env.REACT_APP_PROJECT_ID === "wshingwell-torstar")
    return "torstar";
  if (process.env.REACT_APP_PROJECT_ID === "wshingwell-branksome-b40a2")
    return "branksome";
  return "default";
};

export const showInstanceName = () => {
  return process.env.REACT_APP_PROJECT_ID !== "wshingwell-branksome-b40a2";
};
