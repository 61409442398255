import { EventAddress } from "./EventAddress";
import { EventList } from "./EventList";
import { Experience } from "./Experience";
import { Organization } from "./Organization";
import { OrganizationMember } from "./OrganizationMember";
import { WshingwellUser, WshingwellUserPermissionRole } from "./WshingwellUser";
import { OrganizationInvitation } from "./OrganizationInvitation";
import { EmailTemplate } from "./EmailTemplate";
import { RsvpAddressFormValues } from "views/Experiences/Rsvp/AddressForm";
import { CustomField } from "./CustomField";
import { AdditionalFee } from "./AdditionalFee";

export interface Payment {
  id: string;
  from: any;
  to: any;
  transaction: string;
  date: firebase.firestore.Timestamp;
  amount: number;
}

export class Contact {
  public id: string;
  public name: string;
  public email: string;
  public type: WshingwellUserPermissionRole | "INVITED";

  public constructor(args: Contact) {
    this.id = args.id;
    this.name = args.name;
    this.email = args.email;
    this.type = args.type;
  }
}

export interface Recipient {
  id?: string;
  email: string;
  displayName: string;
}

export type RSVP = "yes" | "no" | "money";

export type TicketType = "ADULT" | "CHILD" | "GUEST" | "GENERAL_ATTENDEE";

export interface GuestNames {
  index: number;
  name: string;
  type: TicketType;
}

export interface EventAttendee {
  name: string;
  donation: number;
  attendanceAmount: number;
  totalCost: number;
  taxAmount: number;
  guestCount: number;
  guestNames: GuestNames[];
  childCount: number;
  adultCount: number;
  generalAttendeeCount: number;
  rsvp: RSVP;
  message: string;
  email: string;
  itemAmount: number;
  addOnPurchases: AdditionalFee[];
  customFields: CustomField[];
  isAddressRequired: boolean;
  address: RsvpAddressFormValues;
  paidOnAccount: boolean;
  accountId: string;
  created: { seconds: number; nanoseconds: number };
}

export interface Cause {
  photo: string;
  title: string;
  startedBy: string;
  target: number;
  raised: number;
  endDate: string;
}

export enum CauseFilter {
  own = "own",
  popular = "popular",
  charities = "charities",
  other = "other",
  past = "past"
}

export type CauseFilterValue<T extends string> = { [key in T]: string };

export const CauseFilterMap = new Map([
  [CauseFilter.popular, "Popular Causes"],
  [CauseFilter.own, "Causes of my own"],
  [CauseFilter.charities, "Registered Charities"],
  [CauseFilter.other, "Other Causes"],
  [CauseFilter.past, "Past Causes"]
]);

export interface GoogleMapsLocation {
  address: string;
  lat: number;
  lng: number;
}

export class ProfileEvents extends Experience {
  public isGoing: boolean;
  public type: "host" | "guest";

  public constructor(args: any) {
    super(args.event);
    this.isGoing = args.isGoing;
    this.type = args.type;
  }
}
export type PayOnAccountOption = "CC" | "CC_AND_ACCT" | "ACCT";

export {
  EventAddress,
  Experience,
  EventList,
  Organization,
  OrganizationMember,
  WshingwellUser,
  OrganizationInvitation,
  EmailTemplate
};
