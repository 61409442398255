export const USERS = "users";
export const CAUSES = "causes";
export const EVENTS = "events";
export const EVENTS_LISTS = "eventsLists";
export const ATTENDEES = "attendees";
export const INVITATIONS = "invitations";
export const CONTRIBUTIONS = "contributions";
export const ORGANIZATIONS = "organizations";
export const CONTACTS = "contacts";
export const ORGANIZATION_INVITATION = "orgInvitation";
export const EMAIL_TEMPLATES = "emailTemplates";
export const PAYMENT_EVENTS = "paymentEvents";
