import { WshingwellUserPermissionRole } from "./WshingwellUser";

export class OrganizationMember {
  public id: string;
  public name: string;
  public email: string;
  public type: WshingwellUserPermissionRole | "INVITED";

  // eslint-disable-next-line
  public constructor(params: any) {
    this.id = params.id;
    this.name = params.name;
    this.email = params.email;
    this.type = params.type;
  }
}

export default OrganizationMember;
