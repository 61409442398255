import classNames from "classnames";
import React, { useContext } from "react";
import { Link as RouterLink, NavLink, useLocation } from "react-router-dom";

import {
  AppBar,
  Avatar,
  Container,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";

import * as ROUTES from "constants/routes";
import MyLink from "components/MyLink";
import { AuthContext } from "../Session";
import { links, HideTags } from "./links";
import { instanceName } from "theme/themeLanguage";
import InstanceLogo from "theme/instanceLogo";
import themeColours from "theme/themeColours";
import { showInstanceName } from "theme/themeSwitch";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0
    }
  },
  appBar: {
    backgroundColor: "#F2F2F2",
    height: "86px",
    color: theme.palette.common.white,
    width: "100%",
    margin: "0 auto"
  },
  linkButton: {
    marginLeft: theme.spacing(4),
    color: "#C4C4C4"
  },
  logoName: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    textDecoration: "none",
    lineHeight: "25px",
    letterSpacing: "-0.65px"
  },
  logo: {
    padding: 0,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(0.5)
  },
  toolbar: theme.mixins.toolbar,
  grow: {
    flexGrow: 1
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 18,
    height: 18,
    color: "currentColor"
  },
  selected: {
    color: theme.palette.tertiary.main,
    "& img": {
      filter: themeColours().iconFilterCss
    }
  },
  avatar: {
    marginLeft: 29,
    height: 32,
    width: 32
  },
  link: {
    display: "flex",
    alignItems: "center"
  }
}));

const Navigation = () => {
  const classes = useStyles();
  const { authUser, user } = useContext(AuthContext);
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const initialLink = links.find((link) => link.path === location.pathname);
  const [selectedLink, setSelectedLink] = React.useState(
    initialLink ? initialLink.title : ""
  );

  const onClickHandler = (selectedLink: string) => {
    setSelectedLink(selectedLink);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (
    location.pathname.includes(
      ROUTES.ONBOARDING.replace("/:redirectToEvent?/:eventId?", "")
    )
  ) {
    return null;
  }

  return (
    <Hidden smDown implementation="js">
      <Container maxWidth="lg" className={classes.container}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar disableGutters className={classes.toolbar}>
            <IconButton
              color="primary"
              aria-label="Open drawer"
              onClick={handleDrawerToggle}
              className={classes.logo}
            >
              <InstanceLogo />
            </IconButton>

            <Grid item className={classes.grow}>
              <NavLink to={ROUTES.LANDING}>
                {showInstanceName() && (
                  <Typography
                    color="initial"
                    variant="h5"
                    align="left"
                    className={classNames("btn-nav__logo", classes.logoName)}
                    onClick={() => onClickHandler("Home")}
                  >
                    {instanceName()}
                  </Typography>
                )}
              </NavLink>
            </Grid>

            {authUser && authUser !== null ? (
              <Hidden smDown implementation="js">
                {links.map((link) =>
                  link.hide === HideTags.HIDE ||
                  (link.hide === HideTags.ADMIN_ONLY &&
                    !user?.isAnyAdmin()) ? null : (
                    <MyLink
                      key={link.title}
                      component={RouterLink}
                      to={link.path}
                      TypographyClasses={{ root: classes.linkButton }}
                      className={classNames(
                        {
                          [classes.selected]: selectedLink === link.title
                        },
                        classes.link
                      )}
                      onClick={() => onClickHandler(link.title)}
                    >
                      <img
                        src={link.icon}
                        className={classes.icon}
                        alt={link.title}
                      />
                      {link.title}
                    </MyLink>
                  )
                )}
                <MyLink key={"Profile"} to="/account">
                  <Avatar
                    onClick={() => onClickHandler("Settings")}
                    alt="Profile Photo"
                    src={user?.photoURL || authUser?.photoURL || undefined}
                    className={classes.avatar}
                  />
                </MyLink>
              </Hidden>
            ) : (
              <Hidden smDown implementation="js">
                <MyLink
                  to={ROUTES.SIGN_IN}
                  className={classNames("btn-nav__signin", classes.linkButton)}
                >
                  Sign In
                </MyLink>
                <MyLink
                  to={ROUTES.LANDING}
                  className={classNames("btn-nav__signup", classes.linkButton)}
                >
                  Sign Up
                </MyLink>
              </Hidden>
            )}
          </Toolbar>
        </AppBar>
      </Container>
    </Hidden>
  );
};

export default Navigation;
