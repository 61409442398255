import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";
import { Link, LinkProps } from "@material-ui/core";

const MyLink: React.FC<LinkProps & RouterLinkProps> = ({
  to,
  onClick,
  color,
  ...otherProps
}) => {
  return (
    <Link
      component={RouterLink}
      to={to}
      onClick={onClick}
      {...otherProps}
      color={color}
    />
  );
};

export default MyLink;
