import FirebaseInstance, { FirebaseService } from "./firebase.service";

import { Contact, WshingwellUser } from "models";
import Organization from "models/Organization";
import { nameOf } from "utils";
import { WshingwellUserPermissionRole } from "models/WshingwellUser";
import { instanceName } from "theme/themeLanguage";
import { EVENTS, ORGANIZATIONS, USERS } from "constants/database";

interface AddOrganizationRequestParams {
  user: WshingwellUser | null;
  name: string;
  website: string;
  relationship: string;
}

/**
 * Service wrapper for manipulating OrganizationService
 */
export class OrganizationService {
  // Reference to core firebase service locator
  private readonly firebase: FirebaseService;

  /**
   * Create a new instance of OrganizationService
   * @param firebase Firebase service locator
   */
  public constructor(firebase: FirebaseService) {
    this.firebase = firebase;
  }

  /**
   * Get organizations from array of strings
   * @param orgIds Array of org ids
   * @returns Array of organizations
   */
  public async getOrganizations(orgIds: string[]) {
    const query = orgIds.map((org) =>
      this.organizationCollectionRef.doc(org).get()
    );

    return Promise.all(query).then((orgs) => {
      return orgs.map((doc) => {
        return new Organization({ id: doc.id, ...doc.data() });
      });
    });
  }

  /**
   * Get all organizations
   * @param limit Optional limit
   * @param offset Optional offset for pagination
   * @returns Array of organizations
   */
  public async getAllOrganizations(limit?: number, offset?: number) {
    const query = this.organizationCollectionRef
      .orderBy(nameOf((_: Organization) => _.displayName))
      .startAt(offset || 0)
      .limit(limit || 100);

    return query.get().then((qs) => {
      return qs.docs.map((doc) => {
        return new Organization({ id: doc.id, ...doc.data() });
      }, []);
    });
  }
  /**
   * Add organization for admins (SystemAdmin, or user.settings.role == ADMIN)
   * @param organization Organization
   * @returns Newly created organization onject, or an error object
   */
  public async adminAddOrganization(organization: Organization) {
    try {
      const checkDuplicate = await this.getOrganizationRef(
        organization.id
      ).get();
      /* eslint-disable */
      // debugger;
      if (!checkDuplicate?.exists) {
        const data = await this.firebase.firestore
          .collection(ORGANIZATIONS)
          .doc(organization.id)
          .set({
            attendeeCut: organization.attendeeCut,
            description: organization.description,
            displayName: organization.displayName,
            photoURL: organization.photoURL,
            servicePercent: organization.servicePercent,
            stripeAccountId: organization.stripeAccountId,
            taxExempt: organization.taxExempt,
            transactionCut: organization.transactionCut,
            waiveOnAccountFees: organization.waiveOnAccountFees,
            website: organization.website
          });
        try {
          console.log("Starting set admins");

          const admins = await this.firebase.firestore
            .collection("systemAdmins")
            .get()
            .then((qs) => qs.docs.map((doc) => doc.id));
          console.log("admins", admins);

          await Promise.all(
            admins.map(async (admin) => {
              console.log("getting admin " + admin);
              const permissions = await this.firebase.firestore
                .collection(USERS)
                .doc(admin)
                .get()
                .then((qs) => qs.data()?.permissions);
              console.log("about to set permissions");
              permissions[organization.id] = ["ADMIN"];
              console.log("setting permissions for " + admin, permissions);
              await this.firebase.firestore
                .collection("users")
                .doc(admin)
                .update("permissions", permissions);
              console.log("done that one");
            })
          );
        } catch (err) {
          console.log("we had an error");
          console.log(err);
        }

        return data;
      } else {
        return {
          error: "Organization identifier is taken"
        };
      }
    } catch (err) {
      console.log(err);
      return {
        error: err
      };
    }
  }

  /**
   * Edit organization for admins (SystemAdmin, or user.settings.role == ADMIN)
   * @param organization Organization
   * @returns Newly created organization onject, or an error object
   */
  public async adminUpdateOrganization(organization: Organization) {
    try {
      // eslint-disable-next-line
      const data = await this.getOrganizationById(organization.id);
      const updatedValues = {
        attendeeCut: organization.attendeeCut ?? data.attendeeCut,
        description: organization.description ?? data.description,
        displayName: organization.displayName ?? data.displayName,
        photoURL: organization.photoURL ?? data.photoURL,
        servicePercent: organization.servicePercent ?? data.servicePercent,
        stripeAccountId: organization.stripeAccountId ?? data.stripeAccountId,
        taxExempt: organization.taxExempt ?? data.taxExempt,
        transactionCut: organization.transactionCut ?? data.transactionCut,
        waiveOnAccountFees:
          organization.waiveOnAccountFees ?? data.waiveOnAccountFees,
        website: organization.website ?? data.website
      };
      const updated = await this.firebase.firestore
        .collection(ORGANIZATIONS)
        .doc(organization.id)
        .set(updatedValues);
      return updatedValues;
    } catch (err) {
      console.log(err);
      return {
        error: err
      };
    }
  }
  /**
   * Get a single organization by id
   * @param id Organization id
   * @returns Organization if found
   */
  public async getOrganizationById(orgId: string) {
    const ds = await this.getOrganizationRef(orgId).get();
    if (ds.exists) {
      return new Organization({ id: ds.id, ...ds.data() });
    }
    throw new Error("Organization not found");
  }

  /**
   * Get a list of favourites from an organization
   * @param orgId Id of organization
   * @returns Array of favourites
   */
  public async getOrgFavourites(orgId: string) {
    return this.getOrganizationRef(orgId)
      .collection("favourites")
      .get()
      .then((qs) => qs.docs.map((doc) => doc.id));
  }

  /**
   * Update an organizations favourite contacts
   * @param userId User to favourite
   * @param orgId ID of organization
   * @param favourite Status (true for favourite, false to remove)
   * @returns Void promise, or null if org does not exist
   */
  public updateFavourites(userId: string, orgId: string, favourite: boolean) {
    const org = this.getOrganizationRef(orgId);

    if (org) {
      if (favourite) {
        return org
          .collection("favourites")
          .doc(userId)
          .set({ favourited: true });
      } else {
        return org.collection("favourites").doc(userId).delete();
      }
    }
    return null;
  }

  /**
   * Get Contacts for and Organization
   * @param org ID of organization
   * @param search Optional search string
   * @returns Array of contacts
   */
  public getOrganizationContacts = async (
    orgId: string,
    search?: string
  ): Promise<Contact[]> => {
    const collectionRef = this.getOrganizationRef(orgId).collection("contacts");

    let query = collectionRef.limit(100);
    if (search) {
      const strSearch = search;
      const strlength = strSearch.length;
      const strFrontCode = strSearch.slice(0, strlength - 1);
      const strEndCode = strSearch.slice(strlength - 1, strSearch.length);

      const startcode = strSearch;
      const endcode =
        strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
      query = query.where("name", ">=", startcode).where("name", "<", endcode);
    }

    return query.get().then((qs) =>
      qs.docs.map(
        (d) =>
          new Contact({
            id: d.id,
            email: d.id,
            type: "INVITED",
            name: d.data().name ?? ""
          })
      )
    );
  };

  /**
   * Update an organizations contacts
   * @param org Organization id to update
   * @param userId ID of user to add
   * @returns Void promise
   */
  public updateOrganizationContacts(org: string, userId: string | undefined) {
    if (userId) {
      const organization = this.getOrganizationRef(org);

      return organization.collection("contacts").doc(userId).delete();
    }
    return;
  }

  /**
   * Get Invited users for an organization
   * @param org Organization to check invited status
   * @returns Invited users
   */
  public getOrganizationInvited = async (orgId: string): Promise<Contact[]> => {
    const collectionRef = this.getOrganizationRef(orgId).collection("invited");
    const query = collectionRef.limit(100);

    return query.get().then((qs) =>
      qs.docs
        .filter((d) => !d.data().accepted)
        .map(
          (d) =>
            new Contact({
              id: d.id,
              email: d.data().email,
              type: "INVITED",
              name: d.data().name ?? "Pending invite"
            })
        )
    );
  };

  /**
   * Update an organization
   * This will trigger /organization/onUpdate cloud function
   * @param orgId the organization object
   * @param photoURL the image
   */
  public updateOrganizationImage(orgId: string, photoURL: string) {
    return this.organizationCollectionRef.doc(orgId).update({ photoURL });
  }

  /**
   * Update an organization
   * This will trigger /organization/onUpdate cloud function
   * @param orgId the organization object
   * @param servicePercent the wshingwell service percent
   */
  public updateOrganizationServicePercent(
    orgId: string,
    servicePercent: number
  ) {
    return this.organizationCollectionRef.doc(orgId).update({ servicePercent });
  }
  /**
   * Update an organization
   * This will trigger /organization/onUpdate cloud function
   * @param orgId the organization object
   * @param attendeeCut the wshingwell service percent
   */
  public updateOrganizationAttendeeCut(orgId: string, attendeeCut: number) {
    return this.organizationCollectionRef.doc(orgId).update({ attendeeCut });
  }

  /**
   * Send email to request the addition of an organization
   * @param param0 Details of request form
   * @returns Void promise
   */
  public async sendAddOrganizationRequest({
    user,
    name,
    website,
    relationship
  }: AddOrganizationRequestParams) {
    const subject =
      instanceName() + " - you've got a new organization request!";
    const content = `
      Dear Admin,

        User ${user?.displayName} (${
      user?.id
    }) has just submitted a request to add the following organization to ${instanceName()}:

        Name: ${name}
        Website: ${website}
        Relationship: ${relationship}

        If you would like to approve the request, please contact the developer team to do so.

      ${instanceName()}
    `;

    const email = {
      to: "support@wshingwell.com",
      from: "do-not-reply@wshingwell.com",
      subject,
      content,
      contentType: "text"
    };

    return this.firebase.functions
      .httpsCallable("sendSimpleEmail")(email)
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  }
  public reinviteUser(ids: string[], organizationUid: string) {
    return this.firebase.functions
      .httpsCallable("reinviteUser")({ ids, organizationUid })
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  }

  /**
   * Update permissions of a user for an org
   * @param newRole Role to assign
   * @param org Organization user is in
   * @param adminUser User updating permissions
   * @param users User ids to update
   * @returns Void promise
   */
  public updateUserOrgPermissions = async (
    newRole: WshingwellUserPermissionRole,
    orgId: string,
    adminUser: string,
    users: string[]
  ) => {
    const data = { newRole, org: orgId, adminUser, users };
    return await this.firebase.functions
      .httpsCallable("updateUserOrgPermissions")(data)
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  };

  /**
   * Get all contacts associated with an organization
   * @param orgId Id of organization
   * @returns Array of contacts for an org
   */
  public getAllOrgContacts = async (orgId: string) => {
    return await this.firebase.functions
      .httpsCallable("getAllOrgContacts")(orgId)
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  };

  /**
   * Get ref to org
   * @param orgId ID of organization
   * @returns Ref to organization
   */
  public getOrganizationRef(orgId: string) {
    return this.organizationCollectionRef.doc(orgId);
  }

  public async getOrganizationActive(orgId: string) {
    const pastDate = new Date();
    pastDate.setMonth(pastDate.getMonth() - 1);
    const checkRegular = await this.firebase.firestore
      .collection(EVENTS)
      .where("org", "==", await this.getOrganizationRef(orgId))
      .where("isDraft", "==", false)
      .where("endDate", ">=", pastDate)
      .get();

    const checkDonation = await this.firebase.firestore
      .collection(EVENTS)
      .where("org", "==", await this.getOrganizationRef(orgId))
      .where("isDraft", "==", false)
      .where("donationEndDate", ">=", pastDate)
      .where("donationStayOpen", "==", true)
      .get();

    return !checkRegular.empty || !checkDonation.empty;
  }

  /**
   * Helper to get ref to organizations collection
   * @returns Document ref to collection
   */
  private get organizationCollectionRef() {
    return this.firebase.firestore.collection("organizations");
  }
}

export default new OrganizationService(FirebaseInstance);
