import { TicketType } from "models";

import {
  WshingwellUserPermissionRole,
  WshingwellUserRole
} from "models/WshingwellUser";

export const THANK_YOU = "thankYou";
export const ONE_DAY_BEFORE = "oneDayBefore";

export const FIREBASE_PERMISSION_ERROR = "Missing or insufficient permissions.";
export const ADMIN_ERROR =
  "User must be a system administrator to perform this action.";

export const permissionRole: { [key: string]: WshingwellUserPermissionRole } = {
  INTERESTED: "INTERESTED",
  MEMBER: "MEMBER",
  ADMIN: "ADMIN"
};

export const userRole: { [key: string]: WshingwellUserRole } = {
  STUDENT: "STUDENT",
  PARENT: "PARENT",
  ADMINISTRATOR: "ADMINISTRATOR"
};

export const ticketType: { [key: string]: TicketType } = {
  ADULT: "ADULT",
  CHILD: "CHILD",
  GUEST: "GUEST",
  GENERAL_ATTENDEE: "GENERAL_ATTENDEE"
};
