import FirebaseInstance, { FirebaseService } from "./firebase.service";
import {
  WshingwellUserSettings,
  WshingwellUserPermissions,
  WshingwellUser
} from "models/WshingwellUser";
import { USERS } from "constants/database";

/**
 * User related calls
 */
export class UserService {
  // Reference to global firebase service locaator
  private readonly firebase: FirebaseService;

  /**
   * Create instance of user service
   * @param firebase - Firebase reference
   */
  public constructor(firebase: FirebaseService) {
    this.firebase = firebase;
  }

  /**
   * Get current logged in user from firebase
   * @returns Current user
   */
  public getCurrentUser() {
    return this.firebase.currentUserRef();
  }
  public async updateUser(email: string, data: Partial<WshingwellUser>) {
    const user = await this.firebase.getUserDocRef(email);
    user.update(data);
  }

  /**
   * Get user ref by email
   * @param email Email of user to get ref of
   * @returns Ref to document
   */
  public getUserRefByEmail(email: string) {
    return this.firebase.getUserDocRef(email);
  }

  /**
   * Update users display name
   * @param displayName Display name to set
   * @returns Void promise
   */
  public updateCurrentUserName(displayName: string) {
    const user = this.getCurrentUser();
    if (user) {
      return user.set({ displayName }, { merge: true });
    }
    return null;
  }

  /**
   * Set users postal code
   * @param postalCode Postal code to set
   * @returns Void promise
   */
  public updateCurrentUserPostalCode(postalCode: string) {
    const user = this.getCurrentUser();
    if (user) {
      return user.set({ postalCode }, { merge: true });
    }
    return null;
  }

  /**
   * Update a individual users favourite contacts
   * @param userId User id to add start to
   * @param favourite Favourite status (t/f)
   */
  public async updateFavourites(userId: string, favourite: boolean) {
    const user = this.getCurrentUser();

    if (user) {
      if (favourite) {
        return user
          .collection("favourites")
          .doc(userId)
          .set({ favourited: true });
      } else {
        return user.collection("favourites").doc(userId).delete();
      }
    }

    return null;
  }

  /**
   * Fetch a users favourites collection
   * @returns array of user ids or null
   */
  public async getUserFavourites() {
    const user = this.getCurrentUser();

    if (user) {
      return user
        .collection("favourites")
        .get()
        .then((qs) => qs.docs.map((doc) => doc.id));
    }
    return null;
  }

  /**
   * Update a users settings and merge with current
   * @param settings Map of settings to update
   */
  public updateCurrentUserSettings(settings: Partial<WshingwellUserSettings>) {
    const user = this.getCurrentUser();
    if (user) {
      return user.set({ settings }, { merge: true });
    }
    return null;
  }

  /**
   * Update a users permissions and merge with current
   * @param permissions Map of permissions to update
   */
  public overwriteCurrentUserPermissions(
    permissions: Partial<WshingwellUserPermissions>
  ) {
    const user = this.getCurrentUser();
    if (user) {
      // remove empty keys
      for (const key in permissions) {
        const p = permissions[key];
        if ((p || []).length === 0) {
          delete permissions[key];
        }
      }
      return user.update("permissions", permissions);
    }
    return null;
  }

  /**
   * Update a users avatar
   * @param avatar File to set avatar as
   * @returns Void promise
   */
  public updateCurrentUserAvatar(avatar: File) {
    const user = this.getCurrentUser();
    if (user) {
      return this.firebase.storage
        .ref("/avatars/" + user.id)
        .put(avatar)
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((downloadURL) =>
          user.set({ photoURL: downloadURL }, { merge: true })
        );
    }
    return null;
  }

  /**
   * Update the current OAuth user
   * @param values Values to set
   */
  public async updateCurrentUser(values: any) {
    return this.firebase.functions
      .httpsCallable("oauth")(values)
      .then(
        (httpCallbableResult: firebase.functions.HttpsCallableResult) =>
          httpCallbableResult.data
      );
  }

  public async reinviteUsers(ids: string[], organizationUid: string) {
    return await this.firebase.functions
      .httpsCallable("reinviteUser")({
        ids,
        organizationUid
      })
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  }

  /**
   * Fetch user data by a given email
   * @param email Email to lookup
   */

  public async getUserByEmail(email: string) {
    const user = await this.firebase.firestore
      .collection(USERS)
      .doc(email)
      .get();
    const userData = user.data();

    if (!userData) {
      return null;
    }
    return new WshingwellUser({
      ...userData
    });
  }

  /**
   * Get number of interested users
   * @param orgId Id of organization to get counts from
   */
  public async getAllInterestedUsersCount(orgId: string) {
    return this.firebase.functions
      .httpsCallable("getAllInterestedUsersCount")(orgId)
      .then((httpCallableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallableResult.data;
      });
  }

  /**
   * Fetch user counts for a given org
   * @param orgId Id of organization to get counts from
   */
  public async getAllMemberAndAdminUsersCount(orgId: string) {
    return this.firebase.functions
      .httpsCallable("getAllMemberAndAdminUsersCount")(orgId)
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  }

  /**
   * Check if user is systemAdmin
   * @param userId Id of user
   * @returns boolean
   */
  public async checkIsSystemAdmin(userId: string) {
    return this.firebase.firestore
      .collection("systemAdmins")
      .doc(userId)
      .get()
      .then((snapshot) => {
        if (!snapshot.exists) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
  /**
   * Fetch user data by a given email
   * @param email Email to lookup
   */

  public async getAllUsers() {
    const user = await this.firebase.firestore.collection(USERS).get();
    return user.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  }
}

export default new UserService(FirebaseInstance);
