import { format, isSameDay, isSameHour, addHours, startOfHour } from "date-fns";

export function removeUndefinedFields<T>(obj: { [J in keyof T]: T[J] }): T {
  Object.keys(obj).forEach(
    (key) => (obj as any)[key] === undefined && delete (obj as any)[key]
  );
  return obj;
}

export type valueOf<T> = T[keyof T];
export function nameOf<T, V extends T[keyof T]>(f: (x: T) => V): string;
export function nameOf(f: (x: any) => any): keyof any {
  const p = new Proxy(
    {},
    {
      get: (_, key) => key
    }
  );
  return f(p);
}

const formatDateString = "MMM dd, yyyy";
const formatTimeString = "h:mma";
const formatDateTimeString = `${formatDateString} ${formatTimeString}`;
/**
 * For a given date interval, return the merge formatted string
 * Example:
 *  start=2019-08-25 10:24AM
 *  end=2019-08-25 15:24AM
 *  returns Aug 25, 2019 10:24AM - 3:24PM
 * @param start Start date
 * @param end End Date
 */
export function mergeDateInterval(start?: Date, end?: Date): string {
  if (!start) {
    return "";
  }
  if (!end) {
    return format(start, formatDateTimeString);
  }

  const sameDay = isSameDay(start, end);
  const sameTime = isSameHour(start, end);

  if (sameDay) {
    if (sameTime) {
      return `${format(start, formatDateTimeString)}`;
    } else {
      return `${format(start, formatDateString)} ${format(
        start,
        formatTimeString
      )} - ${format(end, formatTimeString)}`;
    }
  } else {
    return `${format(start, formatDateTimeString)} - ${format(
      end,
      formatDateTimeString
    )}`;
  }
}

export function formatDate(date: Date): string {
  return format(date, formatDateString);
}
export function formatDateTime(date: Date): string {
  return format(date, formatDateTimeString);
}

export function formatDateToHour(date: Date, hours: number) {
  return startOfHour(addHours(date, hours + 1));
}

export function currencyFormat(value: string | number): string {
  const formatted = Number(value);
  if (Number.isNaN(formatted)) return "";
  const rounded = Number(formatted.toFixed(2));
  return rounded.toLocaleString("en-US", { minimumFractionDigits: 2 });
}
