import React from "react";

import { WshingwellUser } from "models";

import firebase from "../Firebase";
import { WithFirebaseProps } from "../Firebase/Context";

export interface WithAuthenticationProps extends WithFirebaseProps {
  authUser: firebase.User | null;
  user: WshingwellUser | null;
}

const AuthUserContext = React.createContext<WithAuthenticationProps>({
  authUser: null,
  user: null,
  firebase
});

export default AuthUserContext;
