import { isEmpty } from "lodash";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { AuthContext } from "../Session";
import { userService } from "services";
import { PageNotFound } from "components/Navigation";

const PrivateRoute: React.FC<
  { component: any; isSystemAdminRoute?: boolean } & RouteProps
> = ({ component, isSystemAdminRoute = false, ...rest }) => {
  const { authUser, user } = React.useContext(AuthContext);
  const isAuthenticated = !isEmpty(authUser);
  // const isEmailVerified = authUser?.emailVerified || false;
  const isRoleUndefined = user?.settings?.role === undefined;
  const isPermissionsUndefined =
    user?.permissions === undefined ||
    Object.keys(user?.permissions).length === 0;
  const Component = component;

  const [
    isLoadingSysadminPermission,
    setIsLoadingSysadminPermission
  ] = React.useState(true);
  const [isSystemAdmin, setIsSystemAdmin] = React.useState(false);

  React.useEffect(() => {
    if (!user?.id) return;
    userService
      .checkIsSystemAdmin(user?.id)
      .then((value) => {
        setIsSystemAdmin(value);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setIsLoadingSysadminPermission(false);
      });
  }, [user]);

  return (
    <Route
      {...rest}
      render={(props) => {
        const { pathname } = props.location;

        if (!isAuthenticated) {
          return pathname === ROUTES.LANDING ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.LANDING,
                state: { from: props.location }
              }}
            />
          );
        }

        if (isSystemAdminRoute && isLoadingSysadminPermission) return null;

        if (isSystemAdminRoute && !isSystemAdmin) {
          return <PageNotFound />;
        }

        // if (!isEmailVerified) {
        //   return pathname === ROUTES.EMAIL_UNVERIFIED ? (
        //     <Component {...props} />
        //   ) : (
        //     <Redirect
        //       to={{
        //         pathname: ROUTES.EMAIL_UNVERIFIED,
        //         state: { from: props.location }
        //       }}
        //     />
        //   );
        // }

        if (isRoleUndefined) {
          return pathname === ROUTES.ROLE_SELECT ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.ROLE_SELECT,
                state: { from: props.location }
              }}
            />
          );
        }

        if (isPermissionsUndefined) {
          return pathname === ROUTES.GROUP_SELECT ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.GROUP_SELECT,
                state: { from: props.location }
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
