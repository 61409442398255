import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import App from "./views/App";
import "./index.scss";

Sentry.init({
  dsn:
    "https://8ef956dc88ca416394999159ce21a215@o311854.ingest.sentry.io/1780604"
});

function renderApp() {
  ReactDOM.render(<App />, document.getElementById("root"));
}

renderApp();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

if ((module as any).hot) {
  (module as any).hot.accept();
}
