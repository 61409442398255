import { EventAddress } from "./EventAddress";
import { Organization } from "./Organization";
import { TicketSplit } from "./TicketSplit";
import { AdditionalFee } from "./AdditionalFee";
import { CustomField } from "./CustomField";
import { EmailOption } from "./EmailOption";
import { PayOnAccountOption } from "models";

class Experience {
  public id: string;
  public name: string; // Small housewarming
  public details: string;
  public host: firebase.firestore.DocumentReference;
  public hostUsername: string; // host username
  public hostDisplayName: string; // alternate host displayname if not the same as the host
  public startDate: Date; //
  public endDate: Date; //
  public rsvpDeadline?: Date;
  public private?: boolean;
  public location: EventAddress;
  public org: Organization;
  public photos: string[];
  public video: string[];
  public pdf?: string;
  public publicList: boolean;
  public attendeeCount: number;
  public childTicketPrice: number;
  public childTicketNumber: number;
  public adultTicketPrice: number;
  public adultTicketNumber: number;
  public generalAttendeeTicketPrice: number;
  public generalAttendeeTicketNumber: number;
  public isDraft: boolean;
  public eventType: string; // 'open' | 'members' | 'inviteOnly'
  public isVirtualEvent: boolean;
  public virtualEventLink: string;
  public ticketSplit?: TicketSplit[];
  public emails?: EmailOption;
  public hasTipJar: boolean;
  public isAddressRequired: boolean;
  public additionalFees: AdditionalFee[];
  public customFields: CustomField[];
  public tipJarLabel?: string;
  public customTipJarLabel?: string;
  public specialConsiderationsLabel?: string;
  public payOnAccount: PayOnAccountOption;
  public guestList: boolean;
  public fundraiser: boolean;
  public hideTicketSplit: boolean;
  public isArchived: boolean;
  public donationStayOpen: boolean;
  public donationEndDate: Date;

  public constructor({ ...args }) {
    this.id = args.id;
    this.name = args.name ? args.name.trim() : undefined;
    this.hostUsername = args.hostUsername ?? "";
    this.hostDisplayName = args.hostDisplayName ?? "";
    this.host = args.host;
    this.startDate = args.startDate;
    this.endDate = args.endDate;
    this.rsvpDeadline = args.rsvpDeadline;
    this.private = args.private;
    this.location = args.location;
    this.org = args.org;
    this.photos = args.photos ?? [];
    this.video = args.video ?? [];
    this.pdf = args.pdf ?? "";
    this.publicList = args.publicList;
    this.attendeeCount = args.attendeeCount;
    this.details = args.details ?? "";
    this.childTicketPrice = args.childTicketPrice;
    this.childTicketNumber = args.childTicketNumber;
    this.adultTicketPrice = args.adultTicketPrice;
    this.adultTicketNumber = args.adultTicketNumber;
    this.generalAttendeeTicketNumber = args.generalAttendeeTicketNumber;
    this.generalAttendeeTicketPrice = args.generalAttendeeTicketPrice;
    this.isDraft = args.isDraft;
    this.eventType = args.eventType;
    this.isVirtualEvent = args.isVirtualEvent;
    this.virtualEventLink = args.virtualEventLink;
    this.ticketSplit = args.ticketSplit;
    this.emails = args.emails;
    this.hasTipJar = args.hasTipJar;
    this.isAddressRequired = args.isAddressRequired ?? false;
    this.additionalFees = args.additionalFees ?? [];
    this.customFields = args.customFields ?? [];
    this.tipJarLabel = args.tipJarLabel ?? "";
    this.customTipJarLabel = args.customTipJarLabel ?? "";
    this.specialConsiderationsLabel = args.specialConsiderationsLabel ?? "";
    this.payOnAccount = args.payOnAccount ?? false;
    this.guestList = args.guestList ?? false;
    this.fundraiser = args.fundraiser ?? false;
    this.hideTicketSplit = args.hideTicketSplit ?? false;
    this.isArchived = args.isArchived ?? false;
    this.donationStayOpen = args.donationStayOpen ?? false;
    this.donationEndDate = args.donationEndDate;
  }
}

export { Experience };
