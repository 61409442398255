import FirebaseInstance, { FirebaseService } from "./firebase.service";
import { EMAIL_TEMPLATES } from "constants/database";
import { EmailTemplate } from "models";
import { userService, eventService } from "./index";

/**
 * Service for handling template related services
 */
export class EmailTemplateService {
  // Reference to firebase service locator
  private readonly firebase: FirebaseService;

  /**
   * Construct a new EmailTemplateService
   * @param firebase Firebase service locator to inject
   */
  public constructor(firebase: FirebaseService) {
    this.firebase = firebase;
  }

  /**
   * Edit Email Template
   * @param eventId Event ID of template
   * @param template ID of template to update
   * @param subject Subject of email
   * @param body Body of email
   * @returns
   */

  public async createOrUpdateEmailTemplate(
    eventId: string,
    template: string,
    subject: string,
    body: string
  ) {
    const templateRef = await this.getEmailTemplateByEventId(eventId, template);
    const userRef = userService.getCurrentUser();
    const eventRef = eventService.getEventRefById(eventId);

    if (templateRef) {
      return this.emailTemplateCollection().doc(templateRef.id).update({
        template,
        userRef,
        eventRef,
        subject,
        body
      });
    }

    return this.emailTemplateCollection().add({
      template,
      userRef,
      eventRef,
      subject,
      body
    });
  }

  /**
   * Fetch a template for an event by template id
   * @param eventId Event id of template
   * @param template Id of template
   * @returns
   */
  public async getEmailTemplateByEventId(eventId: string, template: string) {
    const eventRef = eventService.getEventRefById(eventId);
    const userRef = userService.getCurrentUser();
    const emailTemplateRef = await this.emailTemplateCollection()
      .where("eventRef", "==", eventRef)
      .where("template", "==", template)
      .where("userRef", "==", userRef)
      .get();
    const emailTemplates = emailTemplateRef.docs.map(
      (_) => new EmailTemplate({ ..._.data(), id: _.id })
    );

    if (emailTemplates.length) return emailTemplates[0];

    return false;
  }

  /**
   * Helper to get email template collection
   * @returns
   */
  private emailTemplateCollection() {
    return this.firebase.firestore.collection(EMAIL_TEMPLATES);
  }
}

export default new EmailTemplateService(FirebaseInstance);
