import React from "react";
import { Box, Typography } from "@material-ui/core";
const PageNotFound = () => (
  <Box
    style={{
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    <Typography variant="body1">Page not found</Typography>
  </Box>
);
export default PageNotFound;
