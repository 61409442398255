import React from "react";

interface AlertContextState {
  open: boolean;
  message?: string;
  showMessage?: (message: string) => void;
}

export const AlertContext = React.createContext<AlertContextState>({
  open: false,
  message: "",
  showMessage: (message: string) => void message
});
