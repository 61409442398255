import themeColours from "./themeColours";

export const theme = {
  breakpoints: {
    values: { xs: 0, lg: 1280, sm: 600, xl: 1920, md: 960 }
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: 86
      },
      "@media (min-width:600px)": { minHeight: 86 }
    }
  },
  shadows: [
    "none",
    "0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
    "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
    "0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
    "0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    "0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
    "0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    "0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
    "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
    "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
    "0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
    "0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
    "0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
    "0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
    "0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
    "0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
    "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
    "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
    "0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
    "0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)"
  ],
  direction: "ltr" as const,
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: "8px"
      }
    },
    MuiButton: {
      root: {
        "&.Mui-disabled": {
          border: "1px solid #D8D8D8"
        }
      },
      contained: {
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        "&.Mui-disabled .MuiButton-label": {
          color: "#D8D8D8",
          "letter-spacing": "-0.0347826px"
        }
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(15px, 14px) scale(1)",
        "font-size": "15px",
        color: "#C4C4C4",
        "&.MuiInputLabel-shrink": {
          "line-height": "18px",
          "letter-spacing": "-0.5px",
          color: "#6A6B6A"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "font-size": "12px",
        "line-height": "18px",
        "letter-spacing": "-0.5px",
        color: "#6A6B6A"
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: "12px",
        lineHeight: "18px",
        "letter-spacing": "-0.5px"
      },
      contained: {
        "margin-left": 0,
        "margin-right": 0
      }
    },
    MuiTextField: {
      root: {
        "& input": {
          color: "#000000",
          height: "unset",
          padding: "15px 16px",
          lineHeight: "18px"
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #E8E6E8"
        }
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "#F9F9F9"
      }
    },
    MuiPickersCalendar: {
      week: {
        marginBottom: "4px"
      }
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        "& .MuiTypography-body1": {
          color: "#000000",
          fontSize: "18px",
          fontWeight: "600"
        }
      },
      iconButton: {
        backgroundColor: "#F9F9F9",
        color: "#000000",
        "&.Mui-disabled": {
          color: "#C4C4C4"
        }
      },
      dayLabel: {
        fontWeight: 600
      }
    },
    MuiPickersDay: {
      day: {
        "& .MuiTypography-body2": {
          color: "#000000",
          fontWeight: 400
        }
      },
      daySelected: {
        borderRadius: "4px",
        "& .MuiTypography-body2": {
          color: "#FFFFFF"
        }
      },
      dayDisabled: {
        "& .MuiTypography-body2": {
          color: "#C4C4C4"
        }
      }
    }
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)"
    },
    duration: {
      standard: 300,
      short: 250,
      enteringScreen: 225,
      shorter: 200,
      leavingScreen: 195,
      shortest: 150,
      complex: 375
    }
  },
  typography: {
    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
    h1: {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.35417em",
      fontSize: "2.25rem",
      fontWeight: 700
    },
    h3: {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.35417em",
      fontSize: "2.25rem",
      fontWeight: 700
    },
    h4: {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.35417em",
      fontSize: "1.5rem",
      fontWeight: 400
    },
    h5: {
      color: themeColours().secondOrgColour,
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.35417em",
      fontSize: "21px",
      fontWeight: 400
    },
    h6: {
      color: themeColours().secondOrgColour,
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.125em",
      fontSize: "1.125rem",
      fontWeight: 600
    },
    display2: {
      marginLeft: "-.02em",
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.13333em",
      fontSize: "2.8125rem",
      fontWeight: 400
    },
    fontWeightLight: 300,
    display3: {
      marginLeft: "-.02em",
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      letterSpacing: "-.02em",
      lineHeight: "1.30357em",
      fontSize: "3.5rem",
      fontWeight: 400
    },
    display4: {
      marginLeft: "-.04em",
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      letterSpacing: "-.04em",
      lineHeight: "1.14286em",
      fontSize: "7rem",
      fontWeight: 300
    },
    fontWeightRegular: 400,
    display1: {
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.20588em",
      fontSize: "2.125rem",
      fontWeight: 400
    },
    button: {
      textTransform: "none",
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      fontSize: "0.875rem",
      fontWeight: 600
    },
    body2: {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.71429em",
      fontSize: "0.875rem",
      fontWeight: 500
    },
    caption: {
      color: "rgba(107,107,107,1)",
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.375em",
      fontSize: "14px",
      fontWeight: 400
    },
    fontSize: 14,
    fontWeightMedium: 500,
    title: {
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.16667em",
      fontSize: "1.3125rem",
      fontWeight: 500
    },
    subheading: {
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.5em",
      fontSize: "1rem",
      fontWeight: 400
    },
    body1: {
      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
      lineHeight: "1.46429em",
      fontSize: "0.875rem",
      fontWeight: 400
    },
    color: {
      primary: {
        light: "#fafafa",
        main: "#333333",
        dark: "#616161"
      }
    },
    useNextVariants: true
  },
  zIndex: {
    modal: 1300,
    snackbar: 1400,
    drawer: 1200,
    appBar: 1100,
    mobileStepper: 1000,
    tooltip: 1500
  },
  shape: { borderRadius: 4 },
  props: {},
  palette: {
    tonalOffset: 0.2,
    background: {
      paper: "rgba(255,255,255,1)",
      default: "rgba(255,255,255,1)"
    },
    contrastThreshold: 3,
    grey: {
      "50": "#fafafa",
      "100": "#f5f5f5",
      "200": "#eeeeee",
      "300": "#e0e0e0",
      "400": "#bdbdbd",
      "500": "#9e9e9e",
      "600": "#757575",
      "700": "#616161",
      "800": "#424242",
      "900": "#212121",
      A700: "#616161",
      A100: "#d5d5d5",
      A400: "#303030",
      A200: "#aaaaaa"
    },
    text: {
      primary: themeColours().secondOrgColour,
      secondary: themeColours().secondaryLight,
      terciary: themeColours().secondaryLight,
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    },
    divider: "rgba(0, 0, 0, 0.12)",
    secondary: {
      main: themeColours().buttonText,
      light: "rgba(255, 203, 202, 1)",
      dark: themeColours().buttonTextDark,
      contrastText: "rgba(255,255,255)"
    },
    common: { black: "#000", white: "#fff" },
    error: {
      light: "#e57373",
      main: "#CA3737",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    type: "light" as const,
    action: {
      hoverOpacity: 0.08,
      hover: "rgba(0, 0, 0, 0.08)",
      selected: "rgba(0, 0, 0, 0.14)",
      disabledBackground: "rgba(249,249,249,1)",
      disabled: "rgba(0, 0, 0, 0.26)",
      active: "rgba(0, 0, 0, 0.54)"
    },
    primary: {
      main: themeColours().secondaryLight,
      light: "rgba(255, 255, 216, 1)",
      dark: "rgba(176, 155, 119, 1)",
      contrastText: themeColours().secondOrgColour
    },
    tertiary: {
      main: themeColours().primaryDark,
      light: themeColours().primaryDark,
      dark: themeColours().primaryDark,
      contrastText: themeColours().primaryDark
    }
  },
  themeName: "Vivid Tangerine Putty Emu"
};
