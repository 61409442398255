import { CustomField } from "models/CustomField";
import FirebaseInstance, { FirebaseService } from "./firebase.service";
import { PAYMENT_EVENTS } from "constants/database";
import { PaymentTransaction } from "models/Payment";
import { RsvpAddressFormValues } from "views/Experiences/Rsvp/AddressForm";
import { AdditionalFee } from "models/AdditionalFee";

type ChargePaymentParams = {
  token: stripe.Token;
  hostId: string;
  userId: string;
  eventId: string;
  attendees: number;
  amount: number;
  donationAmount: number;
  rsvpData: RsvpData;
};
export type RsvpData = {
  userId: string;
  eventId: string;
  rsvp: {
    answer: "yes" | "no" | "money";
    guestCount: number;
    adultCount: number;
    childCount: number;
    generalAttendeeCount: number;
    donation: number;
    attendanceAmount: number;
    taxAmount: number;
    totalCost: number;
    guestNames?: object;
    message?: string;
    addOnPurchases?: AdditionalFee[];
    customFields?: CustomField[];
    itemAmount?: number;
    isAddressRequired: boolean;
    address: RsvpAddressFormValues;
    accountId: string;
    paidOnAccount: boolean;
  };
};

export class PaymentService {
  // Firebase service locator
  private readonly firebase: FirebaseService;

  /**
   * Create a new instance of firebase service
   * @param firebase Firebase service locator
   */
  public constructor(firebase: FirebaseService) {
    this.firebase = firebase;
  }

  /**
   * Fetch stripe account id for user
   * @param userId Id of user to get stripe id for
   * @returns Stripe id or null
   */
  public async getStripeAccountId(userId: string) {
    return this.firebase.firestore
      .collection("users")
      .doc(userId)
      .get()
      .then((snapshot) => {
        const { stripeAccountId = "" } = snapshot.data() || {};
        if (!snapshot.exists || !stripeAccountId) {
          throw new Error("No stripe accountId");
        } else {
          return stripeAccountId;
        }
      })
      .catch(() => {
        return null;
      });
  }

  /**
   * Get link to setting up payment account
   * @description In order to use stripe payments, you need an account.
   *  These are created in a function, and a link is returned to setup.
   * @param successUrl Success redirect url
   * @param failureUrl Failure redirect url
   * @returns
   */
  public getSetupPaymentsAccountLink = (
    successUrl: string,
    failureUrl: string
  ) => {
    const f = this.firebase.functions.httpsCallable(
      "getSetupPaymentsAccountLink"
    );
    return f({ successUrl, failureUrl });
  };

  /**
   * Charge a payment
   * @param params Stripe charge information
   * @returns Promise
   */
  public chargePayment = (params: ChargePaymentParams) => {
    const f = this.firebase.functions.httpsCallable("chargePayment");
    return f(params);
  };

  /**
   * Maps raw stripe responses to readable result
   * @param qs Snapshot query
   * @returns Formatted Payment object
   */
  private mapTransaction(event: firebase.firestore.QueryDocumentSnapshot) {
    const {
      data: {
        amount,
        created,
        id,
        metadata: { from, to, eventName, eventOrg }
      }
    } = event.data();
    return new PaymentTransaction({
      id,
      date: created,
      minimumFee: amount / 100,
      from,
      to,
      eventName,
      orgId: eventOrg
    });
  }

  private mapAllTransactionData(
    event: firebase.firestore.QueryDocumentSnapshot
  ) {
    const data = event.data();
    return data;
  }

  public async getAllTransactions(email: string) {
    const [receivedEvent, givenEvent] = await Promise.all([
      this.firebase.firestore
        .collection(PAYMENT_EVENTS)
        .where("data.metadata.to", "==", email)
        .where("type", "==", "transfer.created")
        .get(),
      this.firebase.firestore
        .collection(PAYMENT_EVENTS)
        .where("data.metadata.from", "==", email)
        .where("type", "==", "transfer.created")
        .get()
    ]);

    const received = receivedEvent.docs.map((event) =>
      this.mapTransaction(event)
    );
    const given = givenEvent.docs.map((event) => this.mapTransaction(event));

    return {
      received,
      given
    };
  }

  public async getTransactionsForExperience(experienceId: string) {
    const data = await this.firebase.firestore
      .collection(PAYMENT_EVENTS)
      .where("data.metadata.eventId", "==", experienceId)
      .get();
    const proc = data.docs.map((event) => this.mapAllTransactionData(event));
    return proc;
  }

  public async refundTransaction(params: {
    chargeId: string;
    transferIds: string[];
  }) {
    const f = this.firebase.functions.httpsCallable("refund")(params);
    return f;
  }
}

export default new PaymentService(FirebaseInstance);
