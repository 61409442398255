import React, { ReactNode, SyntheticEvent, useState } from "react";
import { Box, IconButton, Snackbar } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AlertContext } from "./Context";

const SimpleSnackbar: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>();

  const showMessage = (message: string) => {
    setOpen(true);
    setMessage(message);
  };

  const handleClose = (_: SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  return (
    <AlertContext.Provider value={{ open, message, showMessage }}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={message}
        action={[
          <Box p={0.5} key="close">
            <IconButton
              aria-label="Close"
              color="inherit"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={["far", "times"]} />
            </IconButton>
          </Box>
        ]}
      />
      {children}
    </AlertContext.Provider>
  );
};

export default SimpleSnackbar;
