import React, { Component, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch
} from "react-router-dom";
import { compose } from "recompose";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { registerIcons } from "utils/registerIcons";

import {
  createStyles,
  CssBaseline,
  Grid,
  MuiThemeProvider as ThemeProvider,
  withStyles,
  WithStyles
} from "@material-ui/core";

import theme from "theme";
import { firebaseService } from "services";
import { WshingwellUser } from "models";
import * as ROUTES from "constants/routes";

import { Snack } from "components/Alerts";
import { FirebaseContext } from "components/Firebase";
import LoadingPage from "components/Loading/Page";
import { BottomNav, TopNav, PageNotFound } from "components/Navigation";
import PrivateRoute from "components/PrivateRoute";
import { AuthContext } from "components/Session";

import { withCacheRefresh } from "hooks/withCacheRefresh";

registerIcons();

// Home
const Home = lazy(withCacheRefresh(() => import("./Home/Home")));

// People
const People = lazy(withCacheRefresh(() => import("./People/People")));
const AddContact = lazy(withCacheRefresh(() => import("./People/AddContact")));
const ContactInvitationSent = lazy(
  withCacheRefresh(() => import("./People/ContactInvitationSent"))
);

// Expereinces
const ExperienceNew = lazy(withCacheRefresh(() => import("./Experiences/New")));
const ExperienceEdit = lazy(
  withCacheRefresh(() => import("./Experiences/Edit"))
);
const ExperienceView = lazy(
  withCacheRefresh(() => import("./Experiences/View"))
);
const ExperiencePublicView = lazy(
  withCacheRefresh(() => import("./Experiences/PublicView"))
);
const ExperienceRsvp = lazy(
  withCacheRefresh(() => import("./Experiences/Rsvp"))
);
const ExperienceSummary = lazy(
  withCacheRefresh(() => import("./Experiences/Summary"))
);
const ExperiencesAttendees = lazy(
  withCacheRefresh(() => import("./Experiences/Attendees"))
);
const ExperiencesEmailEdit = lazy(
  withCacheRefresh(() => import("./Experiences/Email/Edit"))
);
const ExperiencesEmailSend = lazy(
  withCacheRefresh(() => import("./Experiences/Email/Send"))
);
// const ExperienceRsvpNotAttending = lazy(
//   withCacheRefresh(() => import("./Experiences/Rsvp/NotAttending"))
// );
const ExperiencesInvitationSearch = lazy(
  withCacheRefresh(() => import("./Experiences/SearchInvitations"))
);
const InvitationAccept = lazy(
  withCacheRefresh(() => import("./People/AcceptInvitation"))
);

// Payments
const Payments = lazy(withCacheRefresh(() => import("./Payments/Payments")));

// Settings
const Settings = lazy(withCacheRefresh(() => import("./Settings/Settings")));

// Authentication
const SignInPage = lazy(
  withCacheRefresh(() => import("./Authentication/SignIn"))
);
const VerifyEmail = lazy(
  withCacheRefresh(() => import("./Authentication/VerifyEmail"))
);

const SignUp = lazy(withCacheRefresh(() => import("./Authentication/SignUp")));
const SignUpNotFound = lazy(
  withCacheRefresh(() => import("./Authentication/SignUp/NotFound"))
);
const EmailVerify = lazy(
  withCacheRefresh(() => import("./Authentication/SignUp/EmailVerify"))
);
const Onboarding = lazy(
  withCacheRefresh(() => import("./Authentication/SignUp/Onboarding"))
);
const PasswordReset = lazy(
  withCacheRefresh(() => import("./Authentication/PasswordReset/PasswordReset"))
);
const PasswordForget = lazy(
  withCacheRefresh(
    () => import("./Authentication/PasswordReset/PasswordForget")
  )
);
const PasswordForgetConfirmation = lazy(
  withCacheRefresh(
    () => import("./Authentication/PasswordReset/PasswordForgetConfirmation")
  )
);
const UserPasswordResetConfirmation = lazy(
  withCacheRefresh(
    () => import("./Authentication/PasswordReset/UserPasswordResetConfirmation")
  )
);
const PasswordResetConfirmation = lazy(
  withCacheRefresh(
    () => import("./Authentication/PasswordReset/PasswordResetConfirmation")
  )
);
const PasswordResetExpired = lazy(
  withCacheRefresh(
    () => import("./Authentication/PasswordReset/PasswordResetExpired")
  )
);

// Organiaztions
const Organization = lazy(withCacheRefresh(() => import("./Organizations")));
const CauseSelection = lazy(
  withCacheRefresh(() => import("./Causes/SelectionPage"))
);
const CauseNew = lazy(withCacheRefresh(() => import("./Causes/New")));

// Other
const Action = lazy(withCacheRefresh(() => import("./Action")));
const OAuth = lazy(withCacheRefresh(() => import("./Authentication/OAuth")));

const ProfilePage = lazy(
  withCacheRefresh(() => import("./People/Profile/ProfilePage"))
);
const RoleSelect = lazy(withCacheRefresh(() => import("./RoleSelect")));
const GroupSelect = lazy(withCacheRefresh(() => import("./GroupSelect")));
const TermsAndConditions = lazy(
  withCacheRefresh(() => import("components/TermsAndConditions"))
);
const VerifiedEmailLanding = lazy(
  withCacheRefresh(
    () => import("views/Authentication/VerifyEmail/VerifiedEmailLanding")
  )
);
const AdminUsers = lazy(
  withCacheRefresh(() => import("views/Admin/Users/AdminUsers"))
);
const AdminOrganizations = lazy(
  withCacheRefresh(() => import("views/Admin/Organizations/AdminOrganizations"))
);
const AdminExperience = lazy(
  withCacheRefresh(
    () => import("views/Admin/Organizations/Experience/AdminExperience")
  )
);
const AdminExperiences = lazy(
  withCacheRefresh(() => import("views/Admin/Events"))
);
const AdminExperienceReport = lazy(
  withCacheRefresh(
    () => import("views/Admin/Organizations/Experience/AdminExperienceReport")
  )
);
const AdminUsersCreate = lazy(
  withCacheRefresh(() => import("views/Admin/Users/Create"))
);

const Admin = lazy(withCacheRefresh(() => import("views/Admin/Admin")));

interface AppProps extends WithStyles, RouteComponentProps {}
interface AppState {
  authUser: firebase.User | null;
  user: WshingwellUser | null;
  isLoading: boolean;
}

class App extends Component<AppProps, AppState> {
  public listeners: firebase.Unsubscribe[] = [];
  public firebase = firebaseService;

  public constructor(props: AppProps) {
    super(props);
    this.state = {
      authUser: null,
      user: null,
      isLoading: true
    };
  }

  public componentDidMount() {
    const authListener = this.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser?.email) {
        // Listen to the doc ref
        const userListener = this.firebase
          .getUserDocRef(authUser.email)
          .onSnapshot((userSnapshot) => {
            this.setState({
              authUser,
              user: new WshingwellUser({
                id: userSnapshot.id,
                ...userSnapshot.data()
              }),
              isLoading: false
            });
          });

        this.listeners.push(userListener);
      } else {
        this.setState({ authUser, user: null, isLoading: false });
      }
    });

    this.listeners.push(authListener);
  }

  public render() {
    const { authUser, isLoading, user } = this.state;
    if (isLoading) {
      return <LoadingPage />;
    }
    const { classes } = this.props;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Snack>
            <FirebaseContext.Provider value={this.firebase}>
              <AuthContext.Provider
                value={{ authUser, user, firebase: this.firebase }}
              >
                <Router>
                  <Suspense fallback={<LoadingPage />}>
                    <Grid container className={classes.topNav}>
                      <TopNav />
                    </Grid>
                    <Grid
                      component="main"
                      direction="column"
                      container
                      className={classes.main}
                    >
                      <Switch>
                        <Route exact path={ROUTES.LANDING} component={SignUp} />
                        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                        <Route
                          path={ROUTES.PASSWORD_FORGET}
                          component={PasswordForget}
                        />
                        <Route
                          path={ROUTES.INVITATION_ACCEPT}
                          component={InvitationAccept}
                        />
                        <PrivateRoute
                          path={ROUTES.EXPERIENCES_INVITE}
                          component={ExperiencesInvitationSearch}
                        />
                        <Route path={ROUTES.OAUTH} component={OAuth} />
                        <PrivateRoute
                          path={ROUTES.EMAIL_UNVERIFIED}
                          component={EmailVerify}
                        />
                        <PrivateRoute
                          path={ROUTES.ONBOARDING}
                          component={Onboarding}
                        />
                        <Route
                          path={ROUTES.PASSWORD_RESET}
                          component={PasswordReset}
                        />
                        <Route
                          path={ROUTES.PASSWORD_FORGET_CONFIRMATION}
                          component={PasswordForgetConfirmation}
                        />
                        <Route
                          path={ROUTES.PASSWORD_RESET_CONFIRMATION}
                          component={PasswordResetConfirmation}
                        />
                        <Route
                          path={ROUTES.EXPIRED_EMAIL_RESET}
                          component={PasswordResetExpired}
                        />
                        <Route
                          path={ROUTES.VERIFY_EMAIL}
                          component={VerifyEmail}
                        />
                        <PrivateRoute
                          path={ROUTES.SIGN_UP_NOT_FOUND}
                          component={SignUpNotFound}
                        />

                        <PrivateRoute
                          path={ROUTES.ACCOUNT}
                          component={Settings}
                        />
                        <PrivateRoute
                          path={ROUTES.ACCOUNT_PASSWORD_RESET_CONFIRMATION}
                          component={UserPasswordResetConfirmation}
                        />
                        <PrivateRoute
                          exact
                          path={ROUTES.PEOPLE}
                          component={People}
                        />
                        <PrivateRoute path={ROUTES.HOME} component={Home} />
                        {/* <Route
                          path={ROUTES.EXPERIENCES_RSVP_NOT_ATTENDING}
                          component={ExperienceRsvpNotAttending}
                        /> */}
                        <PrivateRoute
                          path={ROUTES.EXPERIENCES_NEW}
                          component={ExperienceNew}
                        />
                        <PrivateRoute
                          path={ROUTES.EXPERIENCES_EDIT}
                          component={ExperienceEdit}
                        />
                        <PrivateRoute
                          path={ROUTES.EXPERIENCES_RSVP}
                          component={ExperienceRsvp}
                        />
                        <PrivateRoute
                          path={ROUTES.EXPERIENCES_SUMMARY}
                          component={ExperienceSummary}
                        />
                        <PrivateRoute
                          exact
                          path={ROUTES.EXPERIENCES_VIEW}
                          component={ExperienceView}
                        />
                        <Route
                          path={ROUTES.EXPERIENCES_PUBLIC_VIEW}
                          component={ExperiencePublicView}
                        />
                        <PrivateRoute
                          exact
                          path={ROUTES.EXPERIENCES_ATTENDEES}
                          component={ExperiencesAttendees}
                        />
                        <PrivateRoute
                          exact
                          path={ROUTES.EXPERIENCES_EMAIL_EDIT}
                          component={ExperiencesEmailEdit}
                        />
                        <PrivateRoute
                          exact
                          path={ROUTES.EXPERIENCES_EMAIL_SEND}
                          component={ExperiencesEmailSend}
                        />
                        <PrivateRoute
                          path={ROUTES.ORGANIZATION}
                          component={Organization}
                        />
                        <PrivateRoute
                          path={ROUTES.PAYMENTS}
                          component={Payments}
                        />
                        <PrivateRoute
                          path={ROUTES.CAUSES_NEW}
                          component={CauseNew}
                        />
                        <PrivateRoute
                          path={ROUTES.CAUSES}
                          component={CauseSelection}
                        />
                        <PrivateRoute
                          path={ROUTES.CONTACTS_NEW}
                          component={AddContact}
                        />
                        <PrivateRoute
                          path={ROUTES.CONTACT_INVITATION_SENT}
                          component={ContactInvitationSent}
                        />
                        <Route path={ROUTES.ACTION} component={Action} />
                        <PrivateRoute
                          path={ROUTES.PEOPLE_VIEW}
                          component={ProfilePage}
                        />
                        <PrivateRoute
                          path={ROUTES.ROLE_SELECT}
                          component={RoleSelect}
                        />
                        <PrivateRoute
                          path={ROUTES.GROUP_SELECT}
                          component={GroupSelect}
                        />
                        <PrivateRoute
                          path={ROUTES.ADMIN_EXPERIENCES}
                          component={AdminExperiences}
                          isSystemAdminRoute
                        />
                        <PrivateRoute
                          path={ROUTES.ADMIN_EXPERIENCE_REPORT}
                          component={AdminExperienceReport}
                          isSystemAdminRoute
                        />
                        <PrivateRoute
                          path={ROUTES.ADMIN_USERS_CREATE}
                          component={AdminUsersCreate}
                          isSystemAdminRoute
                        />
                        <PrivateRoute
                          path={ROUTES.ADMIN_USERS}
                          component={AdminUsers}
                          isSystemAdminRoute
                        />
                        <PrivateRoute
                          path={ROUTES.ADMIN_EXPERIENCE}
                          component={AdminExperience}
                          isSystemAdminRoute
                        />

                        <PrivateRoute
                          path={ROUTES.ADMIN_ORG}
                          component={AdminOrganizations}
                          isSystemAdminRoute
                        />

                        <PrivateRoute
                          path={ROUTES.ADMIN}
                          component={Admin}
                          isSystemAdminRoute
                        />

                        <Route
                          path={ROUTES.TERMS_CONDITIONS}
                          render={({ history }) => (
                            <TermsAndConditions
                              open
                              onClose={() => history.goBack()}
                            />
                          )}
                        />
                        <Route
                          path={ROUTES.VERIFIED_EMAIL_LANDING}
                          component={VerifiedEmailLanding}
                        />
                        <Route component={PageNotFound} />
                      </Switch>
                    </Grid>
                    <BottomNav />
                  </Suspense>
                </Router>
              </AuthContext.Provider>
            </FirebaseContext.Provider>
          </Snack>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }

  public componentWillUnmount() {
    if (this.listeners) {
      this.listeners.forEach((l) => l());
    }
  }
}

const styles = createStyles(() => ({
  topNav: {
    backgroundColor: "#F2F2F2"
  },
  bottomNav: {
    bottom: 0,
    height: 60,
    position: "static"
  },
  main: {
    flex: "1 1 auto",
    boxOrient: "horizontal",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#F2F2F2"
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.common.white
    }
  }
}));

export default compose<AppProps, any>(withStyles(styles))(App);
