import React from "react";

type LazyFactory = Parameters<typeof React.lazy>[0];

export const withCacheRefresh = (
  importResult: LazyFactory
): LazyFactory => () =>
  importResult().catch((err) => {
    if (err.toString().indexOf("ChunkLoadError") > -1) {
      window.location.reload(true);
      throw new Error("[ChunkLoadError] Component chunk could not be found!");
    }
    throw new Error("Component failed to load!");
  });
