import { EventList, Experience, WshingwellUser } from "models";
import { AdditionalFee } from "models/AdditionalFee";
import { finRound } from "./finRound";

export const calculateRsvpCosts = ({
  childGuests,
  adultGuests,
  generalAttendeeGuests,
  childTicketPrice,
  adultTicketPrice,
  generalAttendeeTicketPrice,
  isVirtualEvent,
  donation,
  addOnPurchases,
  taxExempt
}: {
  childGuests: number;
  adultGuests: number;
  generalAttendeeGuests: number;
  childTicketPrice: number;
  adultTicketPrice: number;
  generalAttendeeTicketPrice: number;
  isVirtualEvent: boolean;
  donation: number;
  addOnPurchases: AdditionalFee[];
  taxExempt: boolean;
}) => {
  // Hardcoded tax to be replaced
  let itemAmount = 0;
  if (addOnPurchases.length) {
    itemAmount += addOnPurchases.reduce(
      (i, add) => (add?.price || 0) * (add?.amount || 0) + i,
      0
    );
  }

  const tax = 0.13;
  const totalChildTicketPrice = (childGuests || 0) * childTicketPrice;
  const totalAdultTicketPrice = (adultGuests || 0) * adultTicketPrice;
  const totalGeneralAttendeeTicketPrice =
    (generalAttendeeGuests || 0) * generalAttendeeTicketPrice;

  const attendanceAmount = isVirtualEvent
    ? finRound(totalGeneralAttendeeTicketPrice)
    : finRound(totalChildTicketPrice + totalAdultTicketPrice);

  let taxAmount = 0;

  if (!taxExempt) taxAmount = finRound((attendanceAmount + itemAmount) * tax);
  const total = finRound(attendanceAmount + itemAmount + taxAmount + donation);

  return {
    attendanceAmount,
    taxAmount,
    donation,
    itemAmount,
    total
  };
};
export const filterEventsByUserPermission = (
  docs: Experience[] | null,
  user: WshingwellUser | null,
  showArchivedOnly: boolean,
  showArchived: boolean
) => {
  return (docs || [])
    .filter((doc: Experience) => {
      const { eventType, org } = doc;
      if (doc.isArchived) {
        if (!showArchived) return null;

        return (showArchivedOnly || showArchived) && user?.isAdmin(org.id)
          ? doc
          : null;
      } else if (showArchivedOnly) return null;

      if (user?.isAdmin(org.id)) return doc;
      switch (eventType) {
        case "open":
          return doc;
        case "members":
          if (user?.isMember(org.id)) {
            return doc;
          }
          break;
        case "inviteOnly":
          if (user?.id === doc?.host?.id) return doc;
          break;
        default:
          break;
      }
      return null;
    })
    .filter((d) => d !== null);
};

export const getTipLabel = (event: Experience) => {
  return event?.customTipJarLabel || event?.tipJarLabel || "Tip Jar";
};

export const getRSVPMessageLabel = (event: Experience) => {
  return event?.specialConsiderationsLabel ?? "";
};

export const DEFAULT_SPECIAL_CONSIDERATIONS_LABEL =
  "Student Name and Grade / Dietary Restrictions / Who referred you";

export const getAttendeeCount = (eventList: EventList) => {
  return eventList?.getAttendeeCount() ?? 0;
};

export const getMaximumAttendeeCount = (event: Experience) => {
  return event.isVirtualEvent
    ? event.generalAttendeeTicketNumber
    : (event?.adultTicketNumber || 0) + (event?.childTicketNumber || 0);
};

export const checkEventEnded = (event: Experience) => {
  if (event.fundraiser) {
    return event.endDate < new Date();
  }
  if (event.donationStayOpen) {
    return event.donationEndDate < new Date();
  }
  return event.endDate < new Date();
};

export const getEventAddOnDisplayName = (
  event: Experience,
  addon: AdditionalFee
) => {
  const { name, displayName, deleted } =
    event?.additionalFees?.find((a) => a.name === addon.name) ?? {};
  return `${displayName ?? name}${deleted ? " (Deleted)" : ""}`;
};
