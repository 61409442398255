import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/performance";
import "firebase/analytics";

import { USERS } from "constants/database";

/**
 * Global firebase config
 */
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASID,
  stripePublishKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  stripeClientId: process.env.REACT_APP_STRIPE_CLIENT_ID,
  stripeAuthorizeURL: process.env.REACT_APP_STRIPE_AUTHORIZE_URL,
  stripeTokenURI: process.env.REACT_APP_STRIPE_TOKEN_URI,
  scopes: [
    "email",
    "profile",
    "https://www.googleapis.com/auth/contacts.readonly"
  ],
  discoveryDocs: ["https://people.googleapis.com/$discovery/rest?version=v1"]
};

/**
 * Parameters for signing up a user
 */
type SignUpEmailParams = {
  email: string;
  password: string;
  displayName: string;
  communicationOptIn: boolean;
  postalCode: string;
};

/**
 * Main Firebase Service
 * @description Handles setup and usage of firebase api
 *
 */
export class FirebaseService {
  // Firebase authentication
  public auth: firebase.auth.Auth;

  // Firebase database
  public firestore: firebase.firestore.Firestore;

  // Firebase functions
  public functions: firebase.functions.Functions;

  // Firebase storage
  public storage: firebase.storage.Storage;

  // Firebase performance
  public perf: firebase.performance.Performance;

  // Firebase analytics
  public analytics: firebase.analytics.Analytics;

  /**
   * Create firebase service
   * @description Initialize the firebase services
   *
   */
  public constructor() {
    firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.firestore = firebase.firestore();
    this.auth.useDeviceLanguage();
    this.functions = firebase.functions();
    this.storage = firebase.storage();
    this.perf = firebase.performance();
    this.analytics = firebase.analytics();
  }

  public async createUser(email: string, displayName: string) {
    const communicationOptIn = false;
    return await this.firestore
      .collection(USERS)
      .doc(email)
      .set(
        {
          email: email,
          displayName,
          postalCode: "",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          settings: {
            emailNewsOptin: communicationOptIn,
            emailHostingOptin: communicationOptIn,
            emailAttendingOptin: communicationOptIn,
            commOptIn: communicationOptIn,
            acceptedTerms: true,
            firstExperience: true,
            showOnboarding: true
          }
        },
        { merge: true }
      );
  }

  /**
   * Sign up with email and password
   * @description Creates an auth user and corresponding user
   *   in the user table.
   *
   * @param config - User details
   * @returns The created credential
   *
   */
  public signUpWithEmailAndPassword = async ({
    email,
    password,
    displayName,
    communicationOptIn,
    postalCode
  }: SignUpEmailParams) => {
    // Create and login user
    return await this.functions
      .httpsCallable("userSignup")({
        email,
        password,
        displayName,
        communicationOptIn,
        postalCode
      })
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  };

  /**
   * Sign in with Email and Password
   * @description Sign a user in using email/password auth
   * @param email Email of user
   * @param password Password of user
   * @returns User Credential
   *
   */
  public signInWithEmailAndPassword = (email: string, password: string) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  };

  /**
   * Sign Out
   * @description Sign a user out of firebase app
   * @returns Void promise
   *
   */
  public signOut = () => {
    return this.auth.signOut();
  };

  /**
   * Password Reset
   * @param email Email of account to reset password for
   * @returns Void promise
   */
  public passwordReset = (email: string) => {
    return this.auth.sendPasswordResetEmail(email);
  };

  /**
   * Verify password reset code
   * @param actionCode Code submitted by user
   * @returns Email address if valid
   */
  public verifyPasswordResetCode = (actionCode: string) => {
    return this.auth.verifyPasswordResetCode(actionCode);
  };

  /**
   * Confirm password reset
   * @param actionCode Code submitted by user
   * @param newPassword Password to change to
   * @returns Void promise
   */
  public confirmPasswordReset = (actionCode: string, newPassword: string) => {
    return this.auth.confirmPasswordReset(actionCode, newPassword);
  };

  /**
   * Change password
   * @param password Password to change to
   * @returns Null if user is not authenticated, void promise otherwise
   */
  public passwordUpdate = (password: string) => {
    if (!this.auth.currentUser) {
      return null;
    }
    return this.auth.currentUser.updatePassword(password);
  };

  /**
   * Apply action code
   * @param actionCode Code to apply
   */
  public applyActionCode = (actionCode: string) => {
    return this.auth.applyActionCode(actionCode);
  };

  /**
   * Get provider for provider id
   * @param providerId Id of provider
   * @returns Provider if found, otherwise null
   */
  public getProviderForProviderId(providerId: string) {
    switch (providerId) {
      case "google.com": {
        return new firebase.auth.GoogleAuthProvider();
      }
      case "facebook.com": {
        return new firebase.auth.FacebookAuthProvider();
      }
      default:
        return null;
    }
  }

  /**
   * Upload a file to storage
   * @param file File to upload
   * @param path Storage path
   * @returns URL of new file
   */
  public async uploadFileToStorage(file: File, path: string) {
    let url: string | null = "";

    // Upload file and metadata to the object
    const uploadTask = await firebase.storage().ref().child(path).put(file);
    await uploadTask.ref.getDownloadURL().then((dl) => (url = dl));

    return url;
  }

  /**
   * Delete a file from storage
   * @param path Path of file
   * @returns Void promise
   */
  public async deleteFileFromStorage(path: string) {
    const fileRef = firebase.storage().ref().child(path);
    await fileRef
      .delete()
      .then(function () {
        console.log("deleted the file");
      })
      .catch(function (error) {
        console.error("failed to delete the file.");
      });
  }

  /**
   * Upload base64 data to storage
   * @param file Base 64 file data
   * @param path Path to upload to
   * @returns Url of new file or null if failure
   */
  public async uploadBase64ToStorage(file: string, path: string) {
    const uploadTask = await firebase
      .storage()
      .ref()
      .child(path)
      .putString(file, "data_url");

    const url = (await uploadTask.ref.getDownloadURL()) as string;
    return url;
  }

  /**
   * Fetches other sign in methods for this email
   * @param email Email to check
   * @returns List of alternative sign in methods
   */
  public fetchSignInMethodsForEmail = (email: string) => {
    return this.auth.fetchSignInMethodsForEmail(email);
  };

  /**
   * Get Auth user email if it exists
   * @returns Email or null
   */
  public getCurrentAuthUserEmail = () => {
    return this.auth?.currentUser?.email ?? null;
  };

  /**
   * Current User Ref
   * @returns Document ref to signed in user
   */
  public currentUserRef = () => {
    const currentAuthUserEmail = this.getCurrentAuthUserEmail();
    if (currentAuthUserEmail) {
      return this.getUserDocRef(currentAuthUserEmail);
    } else {
      return null;
    }
  };

  /**
   * Get User Document Ref of user by email
   * @param email Email of user
   * @returns Document Ref
   */
  public getUserDocRef = (email?: string) => {
    return this.firestore.collection(USERS).doc(email);
  };

  /**
   * Get User Snapshot
   * @returns Document snapshot to signed in user
   */
  public getUserSnapshot = (email?: string) => {
    return this.getUserDocRef(email).get();
  };
}

const FirebaseInstance = new FirebaseService();
export default FirebaseInstance;
