import ExperiencesIcon from "./icons/experiences.svg";
import HomeIcon from "./icons/home.svg";
import PaymentsIcon from "./icons/payments.svg";
import PeopleIcon from "./icons/people.svg";
import SettingsIcon from "./icons/settings.svg";
import * as ROUTES from "constants/routes";

export enum HideTags {
  HIDE,
  SHOW,
  ADMIN_ONLY
}

export const links = [
  {
    title: "Home",
    icon: HomeIcon,
    path: ROUTES.HOME,
    hide: HideTags.SHOW
  },
  {
    title: "People",
    icon: PeopleIcon,
    path: ROUTES.PEOPLE,
    hide: HideTags.ADMIN_ONLY
  },
  {
    title: "Experiences",
    icon: ExperiencesIcon,
    path: ROUTES.EXPERIENCES,
    hide: HideTags.HIDE
  },
  {
    title: "Payments",
    icon: PaymentsIcon,
    path: ROUTES.PAYMENTS,
    hide: HideTags.SHOW
  },
  {
    title: "Settings",
    icon: SettingsIcon,
    path: ROUTES.ACCOUNT,
    hide: HideTags.SHOW
  }
];
