import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import themeColours from "theme/themeColours";

const useStyles = makeStyles(() => ({
  colorPrimary: {
    color: themeColours().secondaryLight
  }
}));

const LoadingPage = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <CircularProgress
        size={200}
        color="primary"
        variant="indeterminate"
        disableShrink
        className={classes.colorPrimary}
      />
    </Grid>
  );
};

export default LoadingPage;
