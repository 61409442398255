import React from "react";
import TorstarLogo from "assets/images/logo_torstar.png";
import BranksomeLogo from "assets/images/logo_branksome.png";

import { ReactComponent as LogoWshingwell } from "assets/images/logo.svg";

import { theme } from "./themeSwitch";

const InstanceLogo = () => {
  if (theme() === "torstar")
    return <img src={TorstarLogo} alt="torstar logo" width={80} height={80} />;
  if (theme() === "branksome")
    return (
      <img src={BranksomeLogo} alt="branksome logo" width={175} height={49} />
    );
  return <LogoWshingwell />;
};

export default InstanceLogo;
