import { AdditionalFee } from "models/AdditionalFee";
import { EventAttendee, RSVP } from "./";
import {
  WSHINGWELL_SERVICE_PERCENT,
  WSHINGWELL_ATTENDEE_CUT,
  WSHINGWELL_TRANSACTION_CUT
} from "constants/payments";
import { Experience } from "./Experience";
import { finRound } from "utils/finRound";

export class EventList {
  public attendees: { [key: string]: EventAttendee };
  public host: { [key: string]: EventAttendee };
  public name: string;

  public constructor(args: any) {
    this.attendees = args.attendees ?? [];
    this.host = args.host ?? "";
    this.name = args.name ?? "";
  }

  /**
   * Get Attendees
   * @description Get array of EventAttendees items for an event
   */
  public getAttendees() {
    return Object.keys(this.attendees).map((email) => ({
      ...this.attendees[email],
      email
    }));
  }

  /**
   * Get Attendee Count
   * @description Get number of attendees attending an event
   */
  public getAttendeeCount() {
    if (this.attendees)
      return Object.keys(this.attendees).reduce(
        (acc, curr) => acc + (this.attendees[curr].guestCount ?? 0),
        0
      );
    return 0;
  }
  public getAttendeeCountByAccount(onAccount: boolean) {
    if (this.attendees)
      return Object.keys(this.attendees).reduce(
        (acc, curr) =>
          acc +
          ((this.attendees[curr].paidOnAccount ?? false) === onAccount
            ? this.attendees[curr].guestCount ?? 0
            : 0),
        0
      );
    return 0;
  }

  /**
   * Get Attendees by RSVP
   * @description Get list of attendees which have rsvp type
   * @param rsvp Type of rsvp
   */
  public getAttendeesByRSVP(rsvp: RSVP) {
    return this.getAttendees().filter((attendee) => attendee.rsvp === rsvp);
  }

  /**
   * Get Donation Amount
   * @description Get total amount of tips/donation/capital campaign for event
   */
  public getDonationAmount() {
    return this.getAttendees().reduce(
      (acc, attendee) => acc + (attendee.donation ?? 0),
      0
    );
  }
  /**
   * Get Tip Count
   * @description Get number of tips/donation/capital campaign paid
   */
  public getTipCount() {
    return this.getAttendees().reduce(
      (acc, attendee) => (attendee.donation ? acc + 1 : acc),
      0
    );
  }

  /**
   * Get Total Ticket Sales
   * @description Get the total amount made off of attendance
   */
  public getTotalTicketSales() {
    return this.getAttendees().reduce(
      (acc, attendee) => acc + finRound(attendee.attendanceAmount),
      0
    );
  }

  public getTotalTicketSalesByAccount(onAccount: boolean) {
    return this.getAttendees()
      .filter((elem) => (elem.paidOnAccount ?? false) === onAccount)
      .reduce((acc, attendee) => acc + finRound(attendee.attendanceAmount), 0);
  }

  /**
   * Get Total Add-on Sales
   * @description Get the total amount made off of add on purchases
   */
  public getTotalAddOnAmount() {
    return this.getAttendees().reduce(
      (i, attendee) =>
        i +
        finRound(
          (attendee.addOnPurchases || []).reduce(
            (j, addOn) => j + (addOn?.price || 0) * (addOn?.amount || 0),
            0
          ) ?? 0
        ),
      0
    );
  }

  public getTotalAddOnAmountByAccount(onAccount: boolean) {
    return this.getAttendees()
      .filter((elem) => (elem.paidOnAccount ?? false) === onAccount)
      .reduce(
        (i, attendee) =>
          i +
          finRound(
            (attendee.addOnPurchases || []).reduce(
              (j, addOn) => j + (addOn?.price || 0) * (addOn?.amount || 0),
              0
            ) ?? 0
          ),
        0
      );
  }
  public getServiceFeeByAttendee = (
    attendee: EventAttendee,
    event: Experience
  ) => {
    if (attendee.totalCost === 0) return 0;
    if (attendee.paidOnAccount && event.org.waiveOnAccountFees) return 0;
    let total = 0;
    total += attendee.paidOnAccount
      ? 0
      : finRound(
          typeof event.org.transactionCut === "number"
            ? event.org.transactionCut
            : WSHINGWELL_TRANSACTION_CUT
        );
    total += attendee.paidOnAccount
      ? 0
      : finRound(
          attendee.guestCount *
            (typeof event.org.attendeeCut === "number"
              ? event.org.attendeeCut
              : WSHINGWELL_ATTENDEE_CUT)
        );

    total += finRound(
      attendee.totalCost *
        (typeof event.org.servicePercent === "number"
          ? event.org.servicePercent
          : WSHINGWELL_SERVICE_PERCENT)
    );
    return finRound(total);
  };

  public getAddOnAmountById(id: string) {
    return this.getAttendees().reduce((acc, attendee) => {
      const amount = (attendee.addOnPurchases || []).reduce(
        (i, a: AdditionalFee) => {
          if (a.name === id) return (a?.price || 0) * (a?.amount || 0);
          else return i;
        },
        0
      );
      return acc + (amount ?? 0);
    }, 0);
  }

  public getAddOnQuantityByFieldName(fieldName: string) {
    return this.getAttendees().reduce((acc, attendee) => {
      const amount = (attendee.addOnPurchases || []).reduce(
        (i, a: AdditionalFee) => {
          if (a.name === fieldName) return a?.amount || 0;
          else return i;
        },
        0
      );
      return acc + (amount ?? 0);
    }, 0);
  }

  /**
   * Get Additional Fee Count
   * @description Count number of times additional fee has been paid
   */
  //  public getAdditionalFeeCount() {
  //   return this.getAttendees().reduce(
  //     (acc, attendee) => acc + (attendee.itemCount ?? 0),
  //     0
  //   );
  // }

  /**  /**
   * Get Total Tips
   * @description Get total tip value
   */
  public getTotalTips() {
    return this.getAttendees().reduce(
      (acc, attendee) => acc + finRound(attendee.donation ?? 0),
      0
    );
  }

  public getTotalTipsByAccount(onAccount: boolean) {
    return this.getAttendees()
      .filter((elem) => (elem.paidOnAccount ?? false) === onAccount)
      .reduce((acc, attendee) => acc + finRound(attendee.donation ?? 0), 0);
  }

  /**
   * Get Service Fee
   * @description Get total service fee amount
   */
  public getServiceFee(event: Experience, eventList: EventList) {
    const data = eventList.getAttendeesByRSVP("yes");
    let total = 0;
    data.forEach((e: EventAttendee) => {
      total += finRound(eventList.getServiceFeeByAttendee(e, event));
    });
    return finRound(total);
  }
  public getServiceFeeByAccount(
    event: Experience,
    eventList: EventList,
    onAccount: boolean
  ) {
    const data = eventList
      .getAttendeesByRSVP("yes")
      .filter(
        (attendee: EventAttendee) => attendee.paidOnAccount === onAccount
      );
    let total = 0;
    data.forEach((e: EventAttendee) => {
      total += finRound(eventList.getServiceFeeByAttendee(e, event));
    });
    return finRound(total);
  }

  /**
   * Get Total Additional Fees Paid
   * @param additionalFee Cost of additional fee
   */
  // public getAdditionalFeeAmount(additionalFee = 0) {
  //   return this.getAdditionalFeeCount() * additionalFee;
  // }

  /**
   * Get Total Event Amount
   * @description Get total revenue generated from event
   */
  public getTotalEventAmount(event: Experience, eventList: EventList) {
    const value =
      this.getTotalTicketSales() +
      this.getTotalTips() +
      this.getTotalAddOnAmount() -
      this.getServiceFee(event, eventList);

    return finRound(value);
  }
}
