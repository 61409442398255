import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faArrowCircleRight,
  faArrowLeft,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faClipboardList,
  faClock,
  faCopy,
  faEdit,
  faEnvelopeOpenDollar,
  faHeart,
  faMapMarker,
  faTicketAlt,
  faTimes,
  faTrash,
  faTrashAlt,
  faWallet,
  faVideo,
  faInfoCircle,
  faTicket,
  faMapMarkerAlt,
  faPlusCircle,
  faPlus,
  faMinus,
  faMinusCircle,
  faPlusSquare,
  faMinusSquare,
  faStar,
  faLockAlt,
  faAngleUp,
  faAngleDown,
  faLink,
  faChevronLeft,
  faChevronRight,
  faEye,
  faEyeSlash,
  faLock,
  faCalendarPlus,
  faPen,
  faImages,
  faShareAlt,
  faFilm,
  faEllipsisH,
  faMoneyBill,
  faExternalLinkAlt,
  faUserPlus,
  faUsers,
  faMailbox,
  faCog,
  faExternalLink,
  faDoorOpen,
  faSave,
  faSearch,
  faAngleRight,
  faQuestionCircle
} from "@fortawesome/pro-regular-svg-icons";

import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";

import {
  faStar as faStarSolid,
  faCaretDown as faCaretDownSolid,
  faCaretUp as faCaretUpSolid,
  faCaretRight as faCaretRightSolid
} from "@fortawesome/pro-solid-svg-icons";

import { faVideo as faVideoLight } from "@fortawesome/pro-light-svg-icons";

export const registerIcons = () => {
  library.add(
    faVideoLight,
    faStarSolid,
    faCaretDownSolid,
    faCaretRightSolid,
    faCaretUpSolid,
    faAngleRight,
    faGoogle,
    faFacebookF,
    faArrowCircleRight,
    faArrowLeft,
    faCalendarAlt,
    faCheck,
    faClipboardList,
    faClock,
    faCopy,
    faEdit,
    faEnvelopeOpenDollar,
    faHeart,
    faMapMarker,
    faTicketAlt,
    faTimes,
    faTrash,
    faTrashAlt,
    faWallet,
    faVideo,
    faInfoCircle,
    faTicket,
    faMapMarkerAlt,
    faPlusCircle,
    faPlus,
    faMinus,
    faMinusCircle,
    faPlusSquare,
    faMinusSquare,
    faStar,
    faLockAlt,
    faAngleUp,
    faAngleDown,
    faLink,
    faChevronLeft,
    faChevronRight,
    faEye,
    faEyeSlash,
    faLock,
    faCalendarPlus,
    faPen,
    faImages,
    faShareAlt,
    faFilm,
    faEllipsisH,
    faMoneyBill,
    faExternalLinkAlt,
    faUserPlus,
    faUsers,
    faMailbox,
    faCheckCircle,
    faCog,
    faExternalLink,
    faDoorOpen,
    faSave,
    faSearch,
    faQuestionCircle
  );
};
