import FirebaseInstance, { FirebaseService } from "./firebase.service";
import { ORGANIZATIONS } from "constants/database";
import { OrganizationInvitation } from "models";

/*************************************************************************************************
 *   IMPORTANT : This invitation just to invite contacts to the organization NOT the the events  *
 ************************************************************************************************/

/**
 * Wrapper for firebase firestore events, eventsLists collection
 */
export class OrganizationInvitationService {
  private readonly firebase: FirebaseService;

  public constructor(firebase: FirebaseService) {
    this.firebase = firebase;
  }

  /**
   * Add an Organizatin Invitation to the collection 'organization'> 'invited'
   * @param OrganizationInvitation
   */
  public createInvite = async (invitation: OrganizationInvitation) => {
    // Create invitation for org, this kicks off our cloud function!
    return this.firebase.firestore
      .collection(ORGANIZATIONS)
      .doc(invitation.org)
      .collection("invited")
      .add({
        org: invitation.org,
        name: invitation.name,
        redirectUrl: invitation.redirectUrl,
        email: invitation.email,
        role: invitation.role
      });
  };
}

export default new OrganizationInvitationService(FirebaseInstance);
