import React from "react";
import { Firebase } from ".";

export const FirebaseContext = React.createContext<Firebase | null>(null);

export interface WithFirebaseProps {
  firebase: Firebase;
}

export const withFirebase = <P extends object>(
  Component: React.ComponentType<P & WithFirebaseProps>
) => {
  const WrapperComponent: React.FC<P> = (props) => {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => firebase && <Component {...props} firebase={firebase} />}
      </FirebaseContext.Consumer>
    );
  };

  return WrapperComponent;
};

export default FirebaseContext;
