export class PaymentTransaction {
  public id: string;
  public from: string;
  public to: string;
  public eventName: string;
  public date: string;
  public minimumFee: number;
  public read: boolean;
  public orgId: string;

  public constructor({ ...args }) {
    this.id = args.id;
    this.from = args.from;
    this.to = args.to;
    this.eventName = args.eventName;
    this.date = args.date;
    this.minimumFee = args.minimumFee;
    this.read = args.read;
    this.orgId = args.orgId;
  }
}
