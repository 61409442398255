import React from "react";
import { withFirebase } from "../Firebase";
import { WithFirebaseProps } from "../Firebase/Context";
import AuthContext, { WithAuthenticationProps } from "./context";

const withAuthentication = <P extends object>(
  Component: React.ComponentType<P & WithAuthenticationProps>
) => {
  // eslint-disable-next-line
  class WithAuthentication extends React.Component<P & WithFirebaseProps> {
    public render() {
      const { firebase } = this.props;
      return (
        <AuthContext.Consumer>
          {({ authUser, user }) =>
            authUser && (
              <Component
                {...this.props}
                authUser={authUser}
                user={user}
                firebase={firebase}
              />
            )
          }
        </AuthContext.Consumer>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
