class EmailTemplate {
  public id: string;
  public template: string;
  public userRef: firebase.firestore.DocumentReference;
  public subject: string;
  public body: string;
  public eventRef: firebase.firestore.DocumentReference;
  public constructor({ ...args }) {
    this.id = args.id;
    this.template = args.template;
    this.userRef = args.userRef;
    this.subject = args.subject;
    this.body = args.body;
    this.eventRef = args.eventRef;
  }
}

export { EmailTemplate };
