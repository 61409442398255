import { theme } from "./themeSwitch";

export const instanceName = () => {
  const themeName = theme();
  if (themeName === "branksome") return "Branksome Hall";

  if (themeName === "torstar") return "TorStar";
  return "Wshingwell";
};
export const donationName =
  theme() === "default" ? "Wshingwell" : "organization";
