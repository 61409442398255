import FirebaseInstance, { FirebaseService } from "./firebase.service";
import { INVITATIONS } from "constants/database";
import { Experience } from "models/Experience";
import { Organization } from "models";

/**
 * Wrapper for firebase firestore events, eventsLists collection
 */
export class InvitationService {
  // Reference to global firebase service locator
  private readonly firebase: FirebaseService;

  /**
   * Create a new InvitationService
   * @param firebase Firebase service locator
   */
  public constructor(firebase: FirebaseService) {
    this.firebase = firebase;
  }

  /**
   * Get an invitation by id
   * @param invitationId ID of invitation
   * @returns Promise to specific invitation
   */
  public getInvitationById(invitationId: string) {
    return this.invitationCollection.doc(invitationId).get();
  }

  /**
   * Get an event associated with an invitation id
   * @param invitationId
   * @returns Experience associated to invitation
   */
  public async getInvitationEvent(invitationId: string) {
    return this.getInvitationById(invitationId).then((invitation) => {
      if (invitation.exists) {
        const invitationData: any = { id: invitation.id, ...invitation.data() };
        return invitationData.event
          .get()
          .then((e: firebase.firestore.DocumentSnapshot) => {
            const data = e.data();
            if (data?.org?.get) {
              return data.org
                .get()
                .then((orgSnap: firebase.firestore.DocumentSnapshot) => {
                  const org = new Organization({
                    id: orgSnap.id,
                    ...orgSnap.data()
                  });
                  const event = new Experience({
                    id: e.id,
                    ...data,
                    ...{ org },
                    startDate: data.startDate ? data.startDate.toDate() : null,
                    endDate: data.endDate ? data.endDate.toDate() : null,
                    rsvpDeadline: data.rsvpDeadline
                      ? data.rsvpDeadline.toDate()
                      : null,
                    donationEndDate: data.donationEndDate
                      ? data.donationEndDate.toDate()
                      : null
                  });
                  invitationData.event = event;
                  return invitationData;
                });
            }
          });
      } else {
        return null;
      }
    });
  }

  /**
   * Update invitation
   * @param invitationId ID of invitation
   * @param data Record to insert into invitation
   * @returns
   */
  public updateInvitation(invitationId: string, data: Record<string, any>) {
    return this.getInvitationRef(invitationId).set(data, { merge: true });
  }

  /**
   * Helper to get reference to invitation collection
   */
  private get invitationCollection() {
    return this.firebase.firestore.collection(INVITATIONS);
  }

  /**
   * Helper to get reference to invitation documents
   * @param invitationId Id of invitation
   * @returns Document
   */
  private getInvitationRef(invitationId: string) {
    return this.invitationCollection.doc(invitationId);
  }
}

export default new InvitationService(FirebaseInstance);
