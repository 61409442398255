import fb from "firebase/app";
import FirebaseInstance, { FirebaseService } from "./firebase.service";
import { permissionRole } from "constants/models";
import {
  ATTENDEES,
  EVENTS,
  EVENTS_LISTS,
  INVITATIONS,
  USERS
} from "constants/database";
import {
  Contact,
  EventAttendee,
  EventList,
  Experience,
  WshingwellUser
} from "models";
import { WshingwellUserPermissions } from "models/WshingwellUser";
import { nameOf, formatDateToHour, removeUndefinedFields } from "utils";
import { isBefore } from "date-fns";
import userService from "./user.service";
// import invitationService from "./invitation.service";
import Organization from "models/Organization";
import organizationService from "./organization.service";
import pick from "lodash/pick";
import { AdditionalFee } from "models/AdditionalFee";
import { RsvpAddressFormValues } from "views/Experiences/Rsvp/AddressForm";
import { instanceName } from "theme/themeLanguage";
import { CustomField } from "models/CustomField";
import { checkEventEnded } from "utils/eventUtils";

export interface RemainingTickets {
  adult: number;
  child: number;
  attendee: number;
  additionalItems: {
    [key: string]: number | null;
  };
}
export interface EmailReminderData {
  inviterFirstName: string;
  recipientName: string;
  event: {
    name: string;
    host: string;
    details: string;
    startDate: string;
    endDate: string;
    ticketTotal: number;
    capacity: string;
    location: string;
    isVirtualEvent: boolean;
    virtualEventLink: string;
  };
}

/**
 * Wrapper for firebase firestore events, eventsLists collection
 */
export class EventService {
  private readonly firebase: FirebaseService;

  public constructor(firebase: FirebaseService) {
    this.firebase = firebase;
  }

  public inviteAttendees(eventId: string, selectedContacts: Contact[]) {
    const invitationCollection = this.firebase.firestore.collection(
      INVITATIONS
    );
    const eventRef = this.firebase.firestore.collection(EVENTS).doc(eventId);
    const batch = this.firebase.firestore.batch();
    for (const contact of selectedContacts) {
      const attendee = eventRef.collection(ATTENDEES).doc(contact.email);
      const invitation = invitationCollection.doc(`${eventId}_${contact.id}`);
      batch.set(attendee, { ...contact }, { merge: true });
      batch.set(
        invitation,
        {
          event: eventRef,
          mailto: contact.email,
          to: this.firebase.firestore
            .collection(EVENTS)
            .doc(eventId)
            .collection(ATTENDEES)
            .doc(contact.id)
        },
        { merge: true }
      );
    }
    return batch.commit();
  }

  public createInvitation(
    fromUserId: string,
    toUserId: string,
    eventId: string
  ) {
    return this.firebase.firestore.collection(INVITATIONS).add({
      from: this.firebase.firestore.collection(USERS).doc(fromUserId),
      to: this.eventCollection()
        .doc(eventId)
        .collection(ATTENDEES)
        .doc(toUserId),
      event: this.firebase.firestore.collection(EVENTS).doc(eventId),
      ts: fb.firestore.Timestamp.now()
    });
  }

  /**
   * Add an event to the collection
   * This will trigger /event/onCreate cloud function
   * @param experience the event object
   */
  public createEvent(experience: Omit<Experience, "id">) {
    const currentUserRef = userService.getCurrentUser();
    if (currentUserRef) {
      return this.eventCollection().add({
        ...experience,
        hostDisplayName:
          (experience.hostDisplayName ?? "").trim() === ""
            ? experience.hostUsername
            : experience.hostDisplayName,
        org: organizationService.getOrganizationRef(experience.org.id),
        host: currentUserRef
      });
    }
    return Promise.reject("Could not find current user");
  }

  /**
   * Update an event
   * This will trigger /event/onUpdate cloud function
   * @param experience the event object
   */
  public updateEvent(experience: Experience) {
    const currentUserRef = userService.getCurrentUser();
    if (currentUserRef) {
      const attributesToUpdate = pick(experience, [
        nameOf((f: Experience) => f.startDate),
        nameOf((f: Experience) => f.endDate),
        nameOf((f: Experience) => f.rsvpDeadline),
        nameOf((f: Experience) => f.details),
        nameOf((f: Experience) => f.name),
        nameOf((f: Experience) => f.childTicketNumber),
        nameOf((f: Experience) => f.childTicketPrice),
        nameOf((f: Experience) => f.adultTicketNumber),
        nameOf((f: Experience) => f.adultTicketPrice),
        nameOf((f: Experience) => f.generalAttendeeTicketPrice),
        nameOf((f: Experience) => f.generalAttendeeTicketNumber),
        nameOf((f: Experience) => f.virtualEventLink),
        nameOf((f: Experience) => f.photos),
        nameOf((f: Experience) => f.video),
        nameOf((f: Experience) => f.pdf),
        nameOf((f: Experience) => f.isDraft),
        nameOf((f: Experience) => f.eventType),
        nameOf((f: Experience) => f.isVirtualEvent),
        nameOf((f: Experience) => f.ticketSplit),
        nameOf((f: Experience) => f.hasTipJar),
        nameOf((f: Experience) => f.tipJarLabel),
        nameOf((f: Experience) => f.customTipJarLabel),
        nameOf((f: Experience) => f.specialConsiderationsLabel),
        nameOf((f: Experience) => f.isAddressRequired),
        nameOf((f: Experience) => f.additionalFees),
        nameOf((f: Experience) => f.customFields),
        nameOf((f: Experience) => f.host),
        nameOf((f: Experience) => f.hostDisplayName),
        nameOf((f: Experience) => f.hostUsername),
        nameOf((f: Experience) => f.ticketSplit),
        nameOf((f: Experience) => f.payOnAccount),
        nameOf((f: Experience) => f.emails),
        nameOf((f: Experience) => f.guestList),
        nameOf((f: Experience) => f.fundraiser),
        nameOf((f: Experience) => f.hideTicketSplit),
        nameOf((f: Experience) => f.isArchived),
        nameOf((f: Experience) => f.donationStayOpen),
        nameOf((f: Experience) => f.donationEndDate)
      ]);

      if (experience["location"]) {
        attributesToUpdate["location"] = experience["location"];
      }
      if ((experience["hostDisplayName"] ?? "").trim() === "") {
        attributesToUpdate["hostDisplayName"] = experience["hostUsername"];
      }

      try {
        const data = this.eventCollection()
          .doc(experience.id)
          .update(attributesToUpdate);
        return data;
      } catch (e) {
        console.log(e);
        Promise.reject("Error updating event.");
      }
    }
    console.log("Could not find current user");
    return Promise.reject("Could not find current user");
  }

  public async reassignEvent(
    event: Experience,
    userId: string,
    userDisplayName: string
  ) {
    const userRef = userService.getUserRefByEmail(userId);
    if (userRef && event) {
      const newEvent = { ...event };
      if (newEvent.ticketSplit && newEvent.ticketSplit.length > 1) {
        newEvent.ticketSplit[1].percentage +=
          newEvent.ticketSplit[0].percentage;
        newEvent.ticketSplit[0].percentage = 0;
      }
      newEvent.host = userRef;
      newEvent.hostUsername = userDisplayName;
      newEvent.hostDisplayName = userDisplayName;
      await this.updateEvent(removeUndefinedFields(newEvent));
      return userRef;
    }
    return false;
  }
  /**
   * List current events for organization
   */
  public async getEventsForOrganization(orgs?: string[]) {
    if (!orgs?.length) {
      return [];
    }

    const batchQueries = this.batchQueries([...orgs]);
    const processDoc = (d: any) => {
      const data = d.data();
      return (
        new Experience({
          id: d.id,
          ...data,
          startDate: data.startDate ? data.startDate.toDate() : null,
          endDate: data.endDate ? data.endDate.toDate() : null,
          rsvpDeadline: data.rsvpDeadline ? data.rsvpDeadline.toDate() : null,
          donationEndDate: data.donationEndDate
            ? data.donationEndDate.toDate()
            : null
        }) || null
      );
    };
    let results = [];
    for (const batch of batchQueries) {
      const batchResult = await this.eventCollection()
        .where(
          nameOf((_: Experience) => _.isDraft),
          "==",
          false
        )

        .where(
          nameOf((_: Experience) => _.org),
          "in",
          batch.map((org) => organizationService.getOrganizationRef(org))
        )
        .orderBy(nameOf((_: Experience) => _.endDate))
        .get()
        .then((qs) => {
          const events: Experience[] = [];
          qs.docs.forEach((d: any) => {
            const event = processDoc(d);
            event && events.push(event);
          }, this);
          return events;
        });
      results.push(batchResult);
    }

    results = results.flat();
    if (orgs.length > 10) {
      // since reaults are queried at batches of 10 org ids
      // if we have >1 batch here, we need to re-order the results
      results = results.sort(function (a, b) {
        const date1: any = new Date(b.startDate);
        const date2: any = new Date(a.startDate);
        return date2 - date1;
      });
    }
    return results.filter((event) => !checkEventEnded(event));
  }

  /**
   * List past events for organization
   */
  public async getPastEventsForOrganization(orgs?: string[]) {
    if (!orgs?.length) {
      return [];
    }

    const batchQueries = this.batchQueries([...orgs]);
    const processDoc = (d: any) => {
      const data = d.data();
      const event =
        new Experience({
          id: d.id,
          ...data,
          startDate: data.startDate ? data.startDate.toDate() : null,
          endDate: data.endDate ? data.endDate.toDate() : null,
          rsvpDeadline: data.rsvpDeadline ? data.rsvpDeadline.toDate() : null,
          donationEndDate: data.donationEndDate
            ? data.donationEndDate.toDate()
            : null
        }) || null;
      if (data.isDraft || !event) return null;
      return event;
    };
    // let results = [];
    let results = [];
    for (const batch of batchQueries) {
      const batchResult = await this.eventCollection()
        .where(
          nameOf((_: Experience) => _.org),
          "in",
          batch.map((org) => organizationService.getOrganizationRef(org))
        )
        .orderBy(
          nameOf((_: Experience) => _.endDate),
          "desc"
        )
        .get()
        .then((qs) => {
          const events: Experience[] = [];
          qs.docs.forEach((d: any) => {
            const event = processDoc(d);
            event && events.push(event);
          }, this);
          return events;
        });

      results.push(batchResult);
    }
    if (orgs.length > 10) {
      // since reaults are queried at batches of 10 org ids
      // if we have >1 batch here, we need to re-order the results
      results = results.sort(function (a: any, b: any) {
        const date1: any = new Date(b.startDate);
        const date2: any = new Date(a.startDate);
        return date1 - date2;
      });
    }
    const f = results.flat();
    return f.filter((event) => checkEventEnded(event));
  }

  /**
   * List current events for host user
   */
  public getEventsForHostUser(userEmail?: string) {
    const userRef = userEmail
      ? this.firebase.getUserDocRef(userEmail)
      : userService.getCurrentUser();

    return this.eventCollection()
      .where("host", "==", userRef)
      .where(
        nameOf((_: Experience) => _.endDate),
        ">=",
        new Date()
      )
      .orderBy(nameOf((_: Experience) => _.endDate))
      .get()
      .then((qs) => {
        return (
          qs.docs.map((d) => {
            const data = d.data();
            return new Experience({
              id: d.id,
              ...data,
              startDate: data.startDate ? data.startDate.toDate() : null,
              endDate: data.endDate ? data.endDate.toDate() : null,
              rsvpDeadline: data.rsvpDeadline
                ? data.rsvpDeadline.toDate()
                : null,
              donationEndDate: data.donationEndDate
                ? data.donationEndDate.toDate()
                : null
            });
          }) || null
        );
      });
  }
  public async getUserHostOrAttending(userEmail: string, eventId: string) {
    const isHost = await this.eventCollection()
      .doc(eventId)
      .get()
      .then((qs) => qs?.data()?.host?.path === USERS + "/" + userEmail);
    const isAttending = await this.getEventListById(eventId).then((el) => {
      if (el?.attendees) {
        const attendeeRsvp = Object.keys(el.attendees).some(
          (attendee) => attendee === userEmail
        );
        if (attendeeRsvp) {
          return true;
        }
      }

      return false;
    });
    return isHost || isAttending;
  }
  public getPastEventsForHostUser(userEmail?: string) {
    const userRef = userEmail
      ? this.firebase.getUserDocRef(userEmail)
      : userService.getCurrentUser();

    return this.eventCollection()
      .where("host", "==", userRef)
      .where(
        nameOf((_: Experience) => _.endDate),
        "<",
        new Date()
      )
      .orderBy(nameOf((_: Experience) => _.endDate))
      .get()
      .then((qs) => {
        let events: Experience[] = [];
        qs.docs.forEach((d) => {
          const data = d.data();
          const event =
            new Experience({
              id: d.id,
              ...data,
              startDate: data.startDate ? data.startDate.toDate() : null,
              endDate: data.endDate ? data.endDate.toDate() : null,
              rsvpDeadline: data.rsvpDeadline
                ? data.rsvpDeadline.toDate()
                : null,
              donationEndDate: data.donationEndDate
                ? data.donationEndDate.toDate()
                : null
            }) || null;
          if (data.isDraft || !event) return;
          events = [...events, event];
        }, this);
        return events;
      });
  }

  public async getRemainingTickets(eventId: string) {
    const capacityData = (
      await this.eventCollection().doc(eventId).get()
    ).data();

    const remainingData: RemainingTickets = {
      adult: 0,
      child: 0,
      attendee: 0,
      additionalItems: {}
    };

    if (capacityData) {
      remainingData.adult = capacityData.adultTicketNumber
        ? capacityData.adultTicketNumber
        : 0;
      remainingData.child = capacityData.childTicketNumber
        ? capacityData.childTicketNumber
        : 0;
      remainingData.attendee = capacityData.generalAttendeeTicketNumber
        ? capacityData.generalAttendeeTicketNumber
        : 0;
      if (capacityData.additionalFees)
        capacityData.additionalFees.forEach((addOn: AdditionalFee) => {
          remainingData.additionalItems[addOn.name] = addOn.amount;
        });
    }

    const rsvpData = (
      await this.eventListCollection().doc(eventId).get()
    ).data();

    Object.values(rsvpData?.attendees).forEach((elem: any) => {
      remainingData.adult -= elem.adultCount;
      remainingData.child -= elem.childCount;
      remainingData.attendee -= elem.generalAttendeeCount;
      if (elem.addOnPurchases)
        elem.addOnPurchases.forEach((addOn: AdditionalFee) => {
          if (remainingData.additionalItems[addOn.name] === null) return;
          if (addOn.amount === null) return;
          remainingData.additionalItems[addOn?.name] =
            (remainingData.additionalItems[addOn?.name] || 0) -
            (addOn?.amount || 0);
        });
    });
    return remainingData;
  }

  public getEventsUserIsInvitedTo(userEmail: string) {
    return this.invitationCollection()
      .where("mailto", "==", userEmail)
      .get()
      .then((querySnapshot) => {
        const eventsInvitedTo: string[] = [];
        querySnapshot.forEach((document) => {
          if (document.exists) {
            const data = document.data();
            if (data.event) {
              eventsInvitedTo.push(data.event.id);
            }
          }
        });

        return Promise.resolve(eventsInvitedTo);
      })
      .then((eventIds) => {
        return this.eventCollection()
          .where(
            nameOf((_: Experience) => _.endDate),
            ">=",
            new Date()
          )
          .get()
          .then((querySnapshot) => {
            const experiences: Experience[] = [];
            querySnapshot.forEach((document) => {
              if (eventIds.includes(document.id)) {
                const data = document.data();

                experiences.push(
                  new Experience({
                    id: document.id,
                    ...data,
                    startDate: data.startDate ? data.startDate.toDate() : null,
                    endDate: data.endDate ? data.endDate.toDate() : null,
                    rsvpDeadline: data.rsvpDeadline
                      ? data.rsvpDeadline.toDate()
                      : null,
                    donationEndDate: data.donationEndDate
                      ? data.donationEndDate.toDate()
                      : null
                  })
                );
              }
            });

            return experiences;
          });
      });
  }

  public getEventsUserIsAttending(userEmail: string) {
    const eventIds: string[] = [];
    const experiencesAttending: Experience[] = [];
    return this.eventListCollection()
      .where(
        new fb.firestore.FieldPath("attendees", userEmail, "rsvp"),
        "==",
        "yes"
      )
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((eventListDocument) => {
          eventIds.push(eventListDocument.id);
        });
      })
      .then(async () => {
        const promises = eventIds.map((event) =>
          this.eventCollection().doc(event).get()
        );

        const snapshots = await Promise.all(promises);

        snapshots.forEach((queryDocumentSnapshot) => {
          const eventDocument = queryDocumentSnapshot.data();

          if (
            eventDocument &&
            isBefore(new Date(), eventDocument.startDate.toDate())
          ) {
            const attendedExperience = new Experience({
              id: queryDocumentSnapshot.id,
              ...eventDocument,
              startDate: eventDocument.startDate
                ? eventDocument.startDate.toDate()
                : null,
              endDate: eventDocument.endDate
                ? eventDocument.endDate.toDate()
                : null,
              rsvpDeadline: eventDocument.rsvpDeadline
                ? eventDocument.rsvpDeadline.toDate()
                : null,
              donationEndDate: eventDocument.donationEndDate
                ? eventDocument.donationEndDate.toDate()
                : null
            });
            experiencesAttending.push(attendedExperience);
          }
        });

        return experiencesAttending;
      });
  }
  public getPastEventsUserIsAttending(userEmail: string) {
    const eventIds: string[] = [];
    const experiencesAttending: Experience[] = [];
    return this.eventListCollection()
      .where(
        new fb.firestore.FieldPath("attendees", userEmail, "rsvp"),
        "==",
        "yes"
      )
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((eventListDocument) => {
          eventIds.push(eventListDocument.id);
        });
      })
      .then(async () => {
        const promises = eventIds.map((event) =>
          this.eventCollection().doc(event).get()
        );

        const snapshots = await Promise.all(promises);

        snapshots.forEach((queryDocumentSnapshot) => {
          const eventDocument = queryDocumentSnapshot.data();

          if (
            eventDocument &&
            !isBefore(new Date(), eventDocument.startDate.toDate())
          ) {
            const attendedExperience = new Experience({
              id: queryDocumentSnapshot.id,
              ...eventDocument,
              startDate: eventDocument.startDate
                ? eventDocument.startDate.toDate()
                : null,
              endDate: eventDocument.endDate
                ? eventDocument.endDate.toDate()
                : null,
              rsvpDeadline: eventDocument.rsvpDeadline
                ? eventDocument.rsvpDeadline.toDate()
                : null,
              donationEndDate: eventDocument.donationEndDate
                ? eventDocument.donationEndDate.toDate()
                : null
            });
            experiencesAttending.push(attendedExperience);
          }
        });

        return experiencesAttending;
      });
  }
  public getAllEvents = async () => {
    const eventsCol = this.eventCollection();
    const events = await eventsCol.get();
    const eventsMap = events.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id
    }));
    return eventsMap;
  };
  public getAllEventsForUser = async (userEmail: string) => {
    const collectionRef = this.eventCollection();
    const userRef = this.firebase.getUserDocRef(userEmail);
    const allAttendees = await this.firebase.firestore
      .collectionGroup(ATTENDEES)
      .where("email", "==", userEmail)
      .get();
    const allAttendeesEvents = allAttendees.docs.map((doc) =>
      doc.ref.parent.parent ? doc.ref.parent.parent.id : ""
    );
    const eventIds = allAttendeesEvents.filter((id) => id !== "");
    let attendingEvents = [] as any[];
    if (eventIds.length) {
      const batchEventIds = this.batchQueries([...eventIds]);
      const results = [];

      for (const batch of batchEventIds) {
        const batchResult = await collectionRef
          .where(fb.firestore.FieldPath.documentId(), "in", batch)
          .get();
        batchResult.docs.map((event) => {
          const data = event.data();
          data.endDate = data.endDate ? data.endDate.toDate() : "";
          data.startDate = data.startDate ? data.startDate.toDate() : "";
          data.rsvpDeadline = data.rsvpDeadline
            ? data.rsvpDeadline.toDate()
            : "";
          data.donationEndDate = data.donationEndDate
            ? data.donationEndDate.toDate()
            : "";
          return { ...data, id: event.id };
        });
        results.push(batchResult);
      }
      attendingEvents = results.flat();
    }

    const hostingEventsData = await collectionRef
      .where("host", "==", userRef)
      .get();
    const hostingEvents = hostingEventsData.docs.map((event) => {
      const data = event.data();
      data.endDate = data.endDate ? data.endDate.toDate() : "";
      data.startDate = data.startDate ? data.startDate.toDate() : "";
      data.rsvpDeadline = data.rsvpDeadline ? data.rsvpDeadline.toDate() : "";
      data.donationEndDate = data.donationEndDate
        ? data.donationEndDate.toDate()
        : "";

      return { ...data, id: event.id };
    });
    return {
      attendingEvents,
      hostingEvents
    };
  };

  public async getAllExperiencesCount(userEmail: string, orgs: string[]) {
    const batchQueries = this.batchQueries([...orgs]);
    let eventsCountTotal = 0;
    for (const batch of batchQueries) {
      const batchResult = await this.eventCollection()
        .where(
          nameOf((_: Experience) => _.endDate),
          ">=",
          new Date()
        )
        .where(
          nameOf((_: Experience) => _.org),
          "in",
          batch.map((org) => organizationService.getOrganizationRef(org))
        )
        .get()
        .then((qs) => {
          let eventsCount = 0;
          qs.docs.forEach((doc) => {
            if (!doc.data().isDraft) {
              eventsCount++;
            }
          });
          return eventsCount;
        });
      eventsCountTotal += batchResult;
    }
    return eventsCountTotal;
  }

  public getEventsHostingCount() {
    const userRef = userService.getCurrentUser();

    return this.eventCollection()
      .where("host", "==", userRef)
      .where(
        nameOf((_: Experience) => _.endDate),
        ">=",
        new Date()
      )
      .orderBy(nameOf((_: Experience) => _.endDate))
      .get()
      .then((qs) => {
        return qs.size || 0;
      });
  }

  public getEventsAttendingCount(userEmail: string) {
    const eventIds: string[] = [];
    let attendedEventCounter = 0;
    return this.eventListCollection()
      .where(
        new fb.firestore.FieldPath("attendees", userEmail, "rsvp"),
        "==",
        "yes"
      )
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((eventListDocument) => {
          eventIds.push(eventListDocument.id);
        });
      })
      .then(async () => {
        const promises = eventIds.map((event) =>
          this.eventCollection().doc(event).get()
        );

        const snapshots = await Promise.all(promises);

        snapshots.forEach((querySnapshot) => {
          const eventDocument = querySnapshot.data();
          if (
            eventDocument &&
            isBefore(new Date(), eventDocument.startDate.toDate())
          ) {
            attendedEventCounter++;
          }
        });

        return attendedEventCounter;
      });
  }
  public async getEventsInterestedCount(
    permissions: WshingwellUserPermissions | undefined
  ) {
    if (permissions) {
      let interestedCount = 0;
      await this.eventCollection()
        .where("isDraft", "==", false)
        .where("eventType", "==", "open")
        .get()
        .then((snapshot) =>
          snapshot.forEach((document) => {
            if (document.exists) {
              const data = document.data();
              const org = data["org"].path.substring(14);
              if (
                Object.keys(permissions).includes(org) &&
                permissions[org].includes(permissionRole.INTERESTED)
              ) {
                interestedCount++;
              }
            }
          })
        );
      return interestedCount;
    }
    return 0;
  }
  public async getEventsInterested(
    permissions: WshingwellUserPermissions | undefined
  ) {
    const experiences: Experience[] = [];
    if (!permissions) {
      return [];
    }

    await this.eventCollection()
      .where("isDraft", "==", false)
      .where("eventType", "==", "open")
      .get()
      .then((snapshot) =>
        snapshot.forEach((document) => {
          if (document.exists) {
            const data = document.data();
            const org = data["org"].path.substring(14);
            if (
              Object.keys(permissions).includes(org) &&
              permissions[org].includes(permissionRole.INTERESTED)
            ) {
              const newExperience = new Experience({
                id: document.id,
                ...data,
                startDate: data.startDate ? data.startDate.toDate() : null,
                endDate: data.endDate ? data.endDate.toDate() : null,
                rsvpDeadline: data.rsvpDeadline
                  ? data.rsvpDeadline.toDate()
                  : null,
                donationEndDate: data.donationEndDate
                  ? data.donationEndDate.toDate()
                  : null
              });
              experiences.push(newExperience);
            }
          }
        })
      );
    return experiences;
  }

  public getEventsInvitedToCount(userEmail: string) {
    return this.invitationCollection()
      .where("mailto", "==", userEmail)
      .get()
      .then((querySnapshot) => {
        const eventsInvitedTo: string[] = [];
        querySnapshot.forEach((document) => {
          if (document.exists) {
            const data = document.data();
            if (data.event) {
              eventsInvitedTo.push(data.event.id);
            }
          }
        });

        return Promise.resolve(eventsInvitedTo);
      })
      .then((eventIds) => {
        return this.eventCollection()
          .where(
            nameOf((_: Experience) => _.endDate),
            ">=",
            new Date()
          )
          .get()
          .then((querySnapshot) => {
            let eventsCount = 0;

            querySnapshot.forEach((document) => {
              if (eventIds.includes(document.id)) {
                eventsCount++;
              }
            });

            return eventsCount;
          });
      });
  }

  public getEventsFilterCount = async (
    userEmail: string,
    isOrgHost: boolean,
    orgs?: string[],
    permissions?: WshingwellUserPermissions
  ) => {
    if (!orgs || !orgs.length) return Promise.resolve([]);

    const getAllExperiencesCountPromise = this.getAllExperiencesCount(
      userEmail,
      orgs
    );
    const getEventsHostingCountPromise = this.getEventsHostingCount();
    const getEventsAttendingCountPromise = this.getEventsAttendingCount(
      userEmail
    );
    const getEventsInvitedToCountPromise = this.getEventsInvitedToCount(
      userEmail
    );
    const getEventsInterestedCountPromise = this.getEventsInterestedCount(
      permissions
    );

    return Promise.all([
      getAllExperiencesCountPromise,
      getEventsHostingCountPromise,
      getEventsAttendingCountPromise,
      getEventsInvitedToCountPromise,
      getEventsInterestedCountPromise
    ]);
  };

  public async copyEvent(eventId: string) {
    const event = await this.getEventById(eventId);
    if (event) {
      event.startDate = formatDateToHour(new Date(), 0);
      event.endDate = formatDateToHour(new Date(), 1);
      event.rsvpDeadline = formatDateToHour(new Date(), 0);
      event.isDraft = true;
      event.name = "Duplicate of " + event.name;
      this.createEvent(event);
    }
  }

  /**
   * Get event by id
   * @param eventId
   */
  public getEventById(eventId: string): Promise<Experience | null> {
    const doc = this.getEventDocRefById(eventId);
    return doc.get().then((ds) => {
      const data = ds.data();
      if (ds.exists && data) {
        const result = new Experience({
          id: ds.id,
          ...data,
          startDate: data.startDate ? data.startDate.toDate() : null,
          endDate: data.endDate ? data.endDate.toDate() : null,
          rsvpDeadline: data.rsvpDeadline ? data.rsvpDeadline.toDate() : null,
          donationEndDate: data.donationEndDate
            ? data.donationEndDate.toDate()
            : null
        });
        if (data.org) {
          return data.org
            .get()
            .then((docSnap: firebase.firestore.DocumentSnapshot) => {
              result.org = new Organization({
                id: docSnap.id,
                ...docSnap.data()
              });
              return result;
            });
        }

        return result;
      } else {
        return null;
      }
    });
  }

  public getEventDocRefById = (eventId: string) =>
    this.eventCollection().doc(eventId);

  /**
   * Get eventList by id
   * @param eventId
   */
  public getEventListById(eventId: string) {
    const doc = this.eventListCollection().doc(eventId);
    return doc.get().then((ds) => {
      const data = ds.data();
      if (ds.exists && data) {
        return new EventList({
          id: ds.id,
          ...data
        });
      }
      return null;
    });
  }

  public rsvpEvent(
    userId: string,
    eventId: string,
    rsvp: {
      answer: "yes" | "no" | "money";
      guestCount: number;
      adultCount: number;
      childCount: number;
      generalAttendeeCount: number;
      donation: number;
      attendanceAmount: number;
      taxAmount: number;
      totalCost: number;
      guestNames?: object;
      message?: string;
      addOnPurchases?: AdditionalFee[];
      customFields?: CustomField[];
      itemAmount?: number;
      isAddressRequired: boolean;
      address: RsvpAddressFormValues;
      accountId: string;
      paidOnAccount: boolean;
    }
  ) {
    return this.firebase.functions
      .httpsCallable("rsvp")({ eventId, userId, rsvp })
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  }

  public adminRsvpEvent(
    eventId: string,
    user: string,
    rsvp: {
      rsvp: "yes" | "no" | "money";
      guestCount: number;
      adultCount: number;
      childCount: number;
      generalAttendeeCount: number;
      donation: number;
      attendanceAmount: number;
      taxAmount: number;
      totalCost: number;
      guestNames?: object;
      message?: string;
      addOnPurchases?: AdditionalFee[];
      customFields?: CustomField[];
      itemAmount?: number;
      isAddressRequired: boolean;
      address: RsvpAddressFormValues;
      accountId: string;
      paidOnAccount: boolean;
    }
  ) {
    return this.firebase.functions
      .httpsCallable("adminRsvp")({ eventId, user, rsvp })
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  }

  public sendgridSendEditConfirmationToUser = async (fields: any) => {
    return await this.firebase.functions
      .httpsCallable("attendingEventAltered")(fields)
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  };

  public deleteAttendee = async (eventId: string, userId: string) => {
    const event = await this.getEventListById(eventId);
    if (event) {
      delete event.attendees[userId];
      await this.eventListCollection()
        .doc(eventId)
        .update({ attendees: event?.attendees });
    } else {
      throw new Error("Failed to find event.");
    }
  };
  public editAttendee = async (
    eventId: string,
    userId: string,
    attendeeData: EventAttendee
  ) => {
    const event = await this.getEventListById(eventId);
    if (event) {
      event.attendees[userId] = attendeeData;
      await this.eventListCollection()
        .doc(eventId)
        .update({ attendees: event?.attendees });
    } else {
      throw new Error("Failed to find event.");
    }
  };

  public getEventRefById(eventId: string) {
    return this.eventCollection().doc(eventId);
  }

  private eventCollection() {
    return this.firebase.firestore.collection(EVENTS);
  }

  private eventListCollection() {
    return this.firebase.firestore.collection(EVENTS_LISTS);
  }

  private invitationCollection() {
    return this.firebase.firestore.collection(INVITATIONS);
  }

  /* this splits the queries into batches of 10
   * because firebase "in" does not support querying <10 ids
   */
  private batchQueries(queries: string[]) {
    const batches = [];
    batches.push(queries.splice(0, 10));
    while (queries.length > 0) {
      batches.push(queries.splice(0, 10));
    }
    return batches;
  }

  public sendReport({
    user,
    message,
    reasons,
    event
  }: {
    user: WshingwellUser | null;
    message: string;
    reasons: string;
    event: Experience;
  }) {
    const subject = instanceName() + " - you've got a report about an event !";
    const content = `
      Dear Admin,

        User ${user?.displayName} (${user?.id}) has just report about an event :

        Event: ${event?.name}

        ${reasons && `He/She selected reason(s) for that :- ${reasons}`}

        and he/she left a message says :-

        "${message.replace(/(?:\r\n|\r|\n)/g, "\n\t")}"

      ${instanceName()}
    `;
    const email = {
      to: "support@wshingwell.com",
      from: "do-not-reply@wshingwell.com",
      subject,
      content,
      contentType: "text"
    };
    return this.firebase.functions
      .httpsCallable("sendSimpleEmail")(email)
      .then((httpCallbableResult: firebase.functions.HttpsCallableResult) => {
        return httpCallbableResult.data;
      });
  }
}

export default new EventService(FirebaseInstance);
