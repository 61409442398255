import { theme } from "./themeSwitch";

const themeColours = () => {
  const themeName = theme();

  if (themeName === "branksome")
    return {
      primaryDark: "#A9D156",
      secondaryLight: "#076324",
      buttonText: "#C7006C",
      buttonTextDark: "#A9D156",
      secondOrgColour: "#2b2b2b",
      iconFilterCss:
        "invert(52%) sepia(75%) saturate(141%) hue-rotate(180deg) brightness(88%) contrast(89%)",
      green: "#669085"
    };

  if (themeName === "torstar")
    return {
      primaryDark: "#173362",
      secondaryLight: "#C3D8EA",
      buttonText: "#285F86",
      buttonTextDark: "#092b42",
      secondOrgColour: "1B9BF1",
      iconFilterCss:
        "invert(52%) sepia(75%) saturate(141%) hue-rotate(180deg) brightness(88%) contrast(89%)",
      green: "#669085"
    };

  return {
    primaryDark: "#649085",
    secondaryLight: "#C1AD8B",
    buttonText: "#FFA1A1",
    buttonTextDark: "#C9696B",
    secondOrgColour: "#786E62",
    iconFilterCss:
      "invert(52%) sepia(75%) saturate(141%) hue-rotate(115deg) brightness(88%) contrast(89%)",
    green: "#669085"
  };
};
export default themeColours;
