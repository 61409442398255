interface OrganizationInvitationParams {
  id?: string;
  email: string;
  name: string;
  role: string;
  redirectUrl: string;
  org: string;
}

class OrganizationInvitation {
  public id?: string;
  public email: string;
  public org: string;
  public name: string;
  public role: string;
  public redirectUrl: string;

  public constructor(args: OrganizationInvitationParams) {
    this.id = args.id;
    this.email = args.email;
    this.name = args.name;
    this.role = args.role;
    this.redirectUrl = args.redirectUrl;
    this.org = args.org;
  }
}

export { OrganizationInvitation };
