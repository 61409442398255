import { createMuiTheme } from "@material-ui/core/styles";
import merge from "lodash/merge";
import { theme } from "./theme";
import themeColours from "./themeColours";

const muiTheme = createMuiTheme(
  merge({}, theme as any, {
    overrides: {
      MuiButton: {
        root: {
          color: theme.palette.primary.contrastText,
          "&$disabled": {
            border: "1px solid rgba(196,196,196,1)"
          }
        },
        disabled: {},
        sizeLarge: {
          borderRadius: 24
        }
      },
      MuiContainer: {
        root: {
          paddingLeft: 0,
          paddingRight: 0
        }
      },
      MuiBottomNavigationAction: {
        root: {
          color: theme.palette.text.disabled,
          "&$selected": {
            color: theme.palette.tertiary.main
          }
        },
        wrapper: {
          color: "inherit"
        },
        label: {
          fontSize: "0.875rem",
          transition: "none",
          transitionDelay: "none"
        },
        "&$selected": {
          padding: 0
        }
      },
      MuiCheckbox: {
        colorSecondary: {
          color: theme.palette.tertiary.main,
          "&$checked": {
            color: theme.palette.tertiary.main
          }
        }
      },
      MuiMenuItem: {
        root: {
          color: theme.palette.text.disabled,
          textDecoration: "none",
          fontStyle: "normal",
          fontSize: 14
        }
      },
      MuiExpansionPanel: {
        root: {
          padding: 0,
          "&::before": {
            content: "none"
          },
          "&$expanded": {
            margin: 0
          }
        }
      },
      MuiExpansionPanelSummary: {
        root: {
          padding: 0
        }
      },
      MuiExpansionPanelDetails: {
        root: {
          padding: 0
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: theme.palette.secondary.dark
        }
      },
      MuiPickerDTTabs: {
        tabs: {
          backgroundColor: theme.palette.secondary.dark
        }
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: theme.palette.secondary.light
          // color: "white"
        }
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: theme.palette.common.white
        },
        toolbarBtnSelected: {
          color: theme.palette.grey[500]
        }
      },
      MuiPickersDay: {
        day: {
          color: theme.palette.secondary.light
        },
        daySelected: {
          backgroundColor: theme.palette.secondary.light
        },
        current: {
          color: theme.palette.secondary.dark
        }
      },
      MuiPickersModal: {
        dialogAction: {
          color: theme.palette.secondary.light
        }
      },
      MuiPickersClock: {
        pin: {
          backgroundColor: theme.palette.secondary.light
        }
      },
      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: theme.palette.secondary.light
        },
        noPoint: {
          backgroundColor: theme.palette.secondary.light
        },
        thumb: {
          borderColor: theme.palette.secondary.light,
          backgroundColor: theme.palette.secondary.light
        }
      },
      MuiPickersClockNumber: {
        clockNumber: {
          color: theme.palette.grey[700]
        },
        clockNumberSelected: {
          color: theme.palette.grey[900]
        }
      },
      MuiMobileStepper: {
        dotActive: {
          backgroundColor: themeColours().primaryDark
        }
      },
      MuiNativeSelect: {
        select: {
          "&:focus": {
            backgroundColor: theme.palette.common.white
          }
        }
      }
    }
  })
);

export default muiTheme;
